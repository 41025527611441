import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPermissions } from './slices';

interface LoadersState {
    layoutLoad: boolean;
    [key: string]: boolean; // Allow for additional dynamic keys
}
export interface IGlobalStates {
    users: any[];
    admins: any[];
    newPractice: any;
    updatedPractice: any;
    practiceFilterParams: {
        sortField: string;
        sortOrder: 'ascend' | 'descend';
        checkedFields: string[];
    };
    currentSectionsById: any[];
    transferLeftSections: any[];
    transferSelectedPracticeId: string;
    transferSelectedPracticeSections: any[];
    clonedSections: any[];
    importedQuestions: any[];
    createSection: any;
    updateSection: any;
    sections: any[];
    practices: any[];
    practicesStructure: any[];
    practiceHistory: any[];
    notification: {
        type: 'success' | 'info' | 'warning' | 'error' | undefined;
        content: string;
    };
    breadCrumbs: string[];
    currentStep: number;
    disableSave: boolean;
    questionValidate: boolean;
    isMobile: boolean;
    isLogged: boolean;
    loaders: LoadersState;
    auth: string | null;
    customViewIds: string[];
    customViewData: any[];
    permissions: null | IPermissions;
}
const initialState: IGlobalStates = {
    users: [],
    admins: [],
    newPractice: null,
    updatedPractice: null,
    practiceFilterParams: {
        sortField: 'name',
        sortOrder: 'ascend',
        checkedFields: [],
    },
    currentSectionsById: [],
    clonedSections: [],
    transferLeftSections: [],
    transferSelectedPracticeId: '',
    transferSelectedPracticeSections: [],
    importedQuestions: [],
    sections: [],
    createSection: null,
    updateSection: null,
    practices: [],
    practicesStructure: [],
    practiceHistory: [],
    breadCrumbs: ['home'],
    notification: {
        type: undefined,
        content: '',
    },
    currentStep: 0,
    isLogged: false,
    disableSave: false,
    isMobile: window.innerWidth < 768,
    questionValidate: false,
    loaders: {
        layoutLoad: false,
    },
    auth: localStorage.getItem('auth') ?? null,
    customViewIds: [],
    customViewData: [],
    permissions: null,
};

const global = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        setCrumbsSlice: (state) => {
            state.breadCrumbs = ['home', ...window.location.pathname.split('/').filter((x) => x != '')];
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setPracticeFilterParams: (state, action) => {
            state.practiceFilterParams = action.payload;
        },
        setLogged: (state, action) => {
            state.isLogged = action.payload;
        },
        setLoaders: (state, action: PayloadAction<Partial<LoadersState>>) => {
            for (const key in action.payload) {
                if (state.loaders.hasOwnProperty(key)) {
                    state.loaders[key] = action.payload[key]!;
                }
            }
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setAdmins: (state, action) => {
            state.admins = action.payload;
        },
        setPractices: (state, action) => {
            state.practices = action.payload;
        },
        setPracticesStructure: (state, action) => {
            state.practicesStructure = action.payload;
        },
        setPractice: (state, action) => {
            state.updatedPractice = action.payload;
        },
        setImportedQuestions: (state, action) => {
            state.importedQuestions = action.payload;
        },
        setTransferLeftSections: (state, action) => {
            state.transferLeftSections = action.payload;
        },
        setTransferSelectedPracticeId: (state, action) => {
            state.transferSelectedPracticeId = action.payload;
        },
        setTransferSelectedPracticeSections: (state, action) => {
            state.transferSelectedPracticeSections = action.payload;
        },
        clearTransferStates: (state) => {
            state.transferSelectedPracticeSections = [];
            state.clonedSections = [];
        },
        setPracticeHistory: (state, action) => {
            state.practiceHistory = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setDisableSave: (state, action) => {
            state.disableSave = action.payload;
        },
        setValidateQuestion: (state, action) => {
            state.questionValidate = action.payload;
        },
        setSection: (state, action) => {
            state.createSection = action.payload;
        },
        setUpdateSection: (state, action) => {
            state.updateSection = action.payload;
        },
        setSectionsById: (state, action) => {
            state.currentSectionsById = action.payload;
            state.transferLeftSections = action.payload;
        },
        setClonedSections: (state, action) => {
            state.clonedSections = action.payload;
        },
        setNewPractice: (state, action) => {
            state.newPractice = action.payload;
        },
        setAuth: (state, action) => {
            state.auth = action.payload;
        },
        setNewUser: (state, action) => {
            state.users = [...state.users, action.payload];
        },
        setNewAdmin: (state, action) => {
            state.admins = [...state.admins, action.payload];
        },
        setNotification: (state, action) => {
            if (action.payload?.type === 'success') {
                state.notification = {
                    type: 'success',
                    content: action.payload?.content ?? 'Successfully done',
                };
            } else {
                state.notification = {
                    type: action.payload?.type ?? 'error',
                    content: action.payload?.content ?? 'Something went wrong',
                };
            }
        },
        setCustomViewIds: (state, action: PayloadAction<string[]>) => {
            state.customViewIds = action.payload;
        },
        setCustomViewData: (state, action: PayloadAction<any[]>) => {
            state.customViewData = Array.isArray(action.payload) ? action.payload : [];
        },
        appendCustomViewData: (state, action: PayloadAction<any>) => {
            if (!state.customViewData.some((item) => item._id === action.payload._id)) {
                state.customViewData = [...state.customViewData, action.payload];
            }
        },
        resetCustomViewIds: (state) => {
            state.customViewIds = [];
        },
        resetCustomViewData: (state) => {
            state.customViewData = [];
        },
        deleteCustomViewItem: (state, action: PayloadAction<string>) => {
            state.customViewIds = state.customViewIds.filter((id) => id !== action.payload);
            state.customViewData = state.customViewData.filter((item) => item._id !== action.payload);
        },
    },
});

export const {
    setAuth,
    setPermissions,
    setUsers,
    setAdmins,
    setLogged,
    setLoaders,
    setNewUser,
    setNewAdmin,
    setSection,
    setSections,
    setPractice,
    setPractices,
    setDisableSave,
    setNewPractice,
    setCrumbsSlice,
    setCurrentStep,
    setSectionsById,
    setNotification,
    setUpdateSection,
    setClonedSections,
    setValidateQuestion,
    setImportedQuestions,
    setPracticesStructure,
    setPracticeFilterParams,
    setTransferLeftSections,
    setTransferSelectedPracticeId,
    setTransferSelectedPracticeSections,
    clearTransferStates,
    setPracticeHistory,
    setCustomViewIds,
    setCustomViewData,
    resetCustomViewIds,
    resetCustomViewData,
    appendCustomViewData,
} = global.actions;
export default global.reducer;
