import { Button, Checkbox, Col, Dropdown, Flex, List, MenuProps } from 'antd';

import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../app/hooks';
import { leftOptions } from './index.config';

interface Props {
    selectedItemsLeft: string[];
    setSelectedItemsLeft: (val: any[]) => void;
    HandleSaveLeft: () => void;
    HandleResetLeft: () => void;
    onMenuClick: MenuProps['onClick'];
}

export default function OriginalList({
    onMenuClick,
    HandleResetLeft,
    selectedItemsLeft,
    setSelectedItemsLeft,
    HandleSaveLeft,
}: Props) {
    const { updatedPractice, currentSectionsById, transferLeftSections } = useAppSelector((state) => state.global);

    return (
        <Col xs={24} sm={24} md={10} lg={10}>
            <Flex justify="space-between" align="center" className="mb-10p">
                <h3>{updatedPractice?.name}</h3>
                <Dropdown.Button
                    style={{ width: 'auto' }}
                    trigger={['click']}
                    menu={{ items: leftOptions, onClick: onMenuClick }}
                    onClick={HandleResetLeft}
                >
                    <FontAwesomeIcon icon={faArrowRotateRight} className="mr-10p" />
                    Reset
                </Dropdown.Button>
            </Flex>
            <List
                className="transfer-list-box"
                bordered
                dataSource={transferLeftSections}
                renderItem={(item) => (
                    <List.Item>
                        <Checkbox
                            className={
                                currentSectionsById.filter((s: any) => s._id == item._id)?.length >= 1
                                    ? ''
                                    : ' transfered-section '
                            }
                            checked={selectedItemsLeft.includes(item._id)}
                            onChange={() => {
                                if (selectedItemsLeft.includes(item._id)) {
                                    setSelectedItemsLeft(selectedItemsLeft.filter((id) => id !== item._id));
                                } else {
                                    setSelectedItemsLeft([...selectedItemsLeft, item._id]);
                                }
                            }}
                        >
                            {item.name}
                        </Checkbox>
                    </List.Item>
                )}
            />
            <Button
                type="primary"
                className="dyn-submit-btn transfer-save-btn"
                onClick={HandleSaveLeft}
                disabled={currentSectionsById?.length == transferLeftSections?.length}
            >
                Save
            </Button>
        </Col>
    );
}
