import { IResponse } from '.';
import { BaseService } from '../baseService';

export class AssessmentsService extends BaseService<any, IResponse> {
    constructor(private apiUrl: string) {
        super(apiUrl);
    }

    async getAssessment(id: string) {
        return await this.getById(id);
    }

    async getAssessments(data: any) {
        return await this.get(data);
    }

    async deleteAssessment(id: string) {
        return await this.delete(id);
    }

    async createAssessment(data: any) {
        return await this.create(data);
    }

    async updateAssessment(data: { id: string; body: any }) {
        return await this.update(data.id, data.body);
    }
}

export const LevelCheckService = new AssessmentsService('/level-check');
export const ExamService = new AssessmentsService('/exam');
export const FinalExamService = new AssessmentsService('/final-exam');
export const HomeworkService = new AssessmentsService('/homework');
