import './index.css';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import Logout from '../logout/Logout';

const { Header } = Layout;
export default function Navbar(props: INavbarProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <Header className="header d-flex-btw">
            <div className="d-flex-btw">
                <Button
                    type="text"
                    style={{ width: 25, height: 64 }}
                    icon={
                        props.collapsed ? (
                            <MenuUnfoldOutlined className="menu-icon" />
                        ) : (
                            <MenuFoldOutlined className="menu-icon" />
                        )
                    }
                    onClick={props.HandleCollapse}
                ></Button>
                <h3 style={{ marginLeft: 15 }}>Admin panel</h3>
            </div>
            <Logout/>
        </Header>
    );
}
