import { Button, Result } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate('/not-found');
    }, [navigate]);

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" onClick={() => navigate('/dashboard')}>
                    Back Home
                </Button>
            }
        />
    );
}
