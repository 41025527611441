import { Checkbox, Col, Form, Row, Typography } from 'antd';
import { rolePermissions } from '../helpers/index.config';
const { Title } = Typography;

const groupedPermissions = rolePermissions.reduce((acc, perm) => {
    const key = perm.label.split(' ')[0]; // Extracts the first word and makes (category) Admin: [{ label: 'Admin', value: 'admin' },]
    if (!acc[key]) acc[key] = [];
    acc[key].push(perm);
    return acc;
}, {} as Record<string, typeof rolePermissions>);

export const RoleCheckbox = () => {
    const groups = Object.entries(groupedPermissions);

    return (
        <Row gutter={[18,18]}>
            {groups.map(([group, permissions]) => (
                <Col xs={24} sm={12} key={group}>
                    <Title level={4}>{group}</Title>
                    <Col>
                        {permissions.map((role) => (
                            <Row key={role.value}>
                                <Form.Item name={role.value} valuePropName="checked" noStyle>
                                    <Checkbox>{role.label}</Checkbox>
                                </Form.Item>
                            </Row>
                        ))}
                    </Col>
                </Col>
            ))}
        </Row>
    );
}