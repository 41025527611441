import { createSlice } from '@reduxjs/toolkit';
import { ISection } from '.';

export interface IGlobalStates {
    sectionIdData: any;
    sections: ISection[];
}
const initialState: IGlobalStates = {
    sectionIdData: null,
    sections: [],
};

const sectionsSlice = createSlice({
    name: 'sections',
    initialState: initialState,
    reducers: {
        setSectionDataById: (state, action) => {
            state.sectionIdData = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
    },
});

export const { setSectionDataById, setSections } = sectionsSlice.actions;
export default sectionsSlice.reducer;
