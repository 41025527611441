import { IPracticeResponse } from '.';

import { BaseService } from '../baseService';

class QuestionService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/questions');
    }

    async getQuestions(data: any) {
        const response = await this.get(data);
        return response;
    }
    async createQuestion(data: any) {
        const response = await this.create(data);
        return response;
    }
    async deleteQuestion(id: string) {
        const response = await this.delete(id);
        return response;
    }
    async getQuestionById(id: string) {
        const response = await this.getById(id);
        return response;
    }
}

export default new QuestionService();
