import { Form, Input, Modal, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useMessage } from '../../../hooks/useMassage';
import axios from 'axios';
import { AdminValues, fetchRoles } from '../helpers';
import adminService from '../../../services/api/api-services/adminService';
import { useEnterModal } from '../../../hooks/useEnterModal';

interface AdminModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    editingItem: AdminValues | null;
}

interface Role {
    _id: string;
    name: string;
}

interface FormValues extends Omit<AdminValues, 'roleId'> {
    role: string;
}

export const AdminModal = ({ isOpen, onClose, onSuccess, editingItem }: AdminModalProps) => {
    const [form] = Form.useForm<FormValues>();
    const { ctxHolder, onError, onSuccess: onSuccessMessage } = useMessage();
    const [roles, setRoles] = useState<Role[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            const result = fetchRoles();
            result.then(setRoles).catch(onError);
            if (editingItem) {
                form.setFieldsValue({
                    name: editingItem.name,
                    login: editingItem.login,
                    role: editingItem.roleId,
                });
            } else {
                form.resetFields();
            }
        }
    }, [isOpen, editingItem, form]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            const formattedValues = {
                name: values.name,
                login: values.login,
                password: values.password,
                roleId: values.role,
            };

            if (editingItem) {
                if (formattedValues.password) {
                    await adminService.updateAdmin(editingItem._id, formattedValues);
                } else {
                    const { password, ...rest } = formattedValues;
                    await adminService.updateAdmin(editingItem._id, rest);
                }
                onSuccessMessage('The admin has been successfully updated.');
            } else {
                await adminService.addAdmin(formattedValues);
                onSuccessMessage('The admin has been successfully created.');
            }

            onSuccess();
            form.resetFields();
            onClose();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                onError(error.response?.data?.message || error.message);
            } else {
                onError((error as Error).message || 'Unknown error');
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEnterModal(isOpen, onSubmit);

    return (
        <>
            {ctxHolder}
            <Modal
                title={editingItem ? 'Edit Admin' : 'Create Admin'}
                open={isOpen}
                onCancel={() => {
                    form.resetFields();
                    onClose();
                }}
                onOk={onSubmit}
                okText={editingItem ? 'Edit' : 'Save'}
                confirmLoading={isLoading}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Admin Name"
                        rules={[{ required: true, message: 'Please input admin name!' }]}
                    >
                        <Input placeholder="Input admin name" />
                    </Form.Item>

                    <Form.Item name="login" label="Login" rules={[{ required: true, message: 'Please input login!' }]}>
                        <Input placeholder="Input login" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: !editingItem, message: 'Please input password' }]}
                    >
                        <Input.Password
                            placeholder={editingItem ? 'Leave blank to keep current password' : 'Input Password'}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select a role' }]}>
                        <Select
                            placeholder="Select a role"
                            options={roles.map((role) => ({
                                label: role.name,
                                value: role._id,
                            }))}
                            loading={isLoading}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
