import { ArrowLeftOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Flex, Table } from 'antd';
import { HistoryColumns, expandedRowRender, fetchGroupOptions } from '../helpers/index.config';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IHistoryTable } from '..';
import SingleSelect from '../../select/SingleSelect';
import GroupRetry from './GroupRetry';

export default function HistoryTable({
    tableData,
    pageSize,
    currentPage,
    setPageSize,
    setCurrentPage,
    handlePagination,
    selectedGroupId,
    defaultGroup,
    handleGroupChange,
    practiceName,
    basePath,
}: IHistoryTable) {
    const navigate = useNavigate();
    const tableColumns: any[] = [...HistoryColumns()];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <Flex vertical gap="large">
            <Flex justify="space-between" style={{ width: '100%' }} align="center">
                <Flex gap={10}>
                    <ArrowLeftOutlined
                        onClick={() => {
                            navigate(`${basePath}`);
                        }}
                    />
                    <h2>{practiceName}</h2>
                </Flex>

                <Flex gap="middle" style={{ width: '33%' }} align="center">
                    <SingleSelect
                        placeholder="Select or search groups"
                        selectedId={selectedGroupId}
                        defaultOption={defaultGroup}
                        handleChange={handleGroupChange}
                        fetchOptions={fetchGroupOptions}
                        size="large"
                    />
                    <Button
                        size="large"
                        style={{ padding: '6px' }}
                        icon={<RedoOutlined style={{ fontSize: '18px' }} />}
                        onClick={showModal}
                    />
                </Flex>
            </Flex>

            <GroupRetry isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

            <Table
                rowKey={'_id'}
                columns={tableColumns.map((col: any) => ({
                    ...col,
                    ellipsis: true,
                    responsive: ['sm', 'xs'],
                }))}
                pagination={{
                    position: ['bottomRight'],
                    current: currentPage,
                    pageSize: pageSize,
                    total: Number(sessionStorage.getItem('totalHistory')),
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                    },
                }}
                dataSource={tableData}
                onChange={handlePagination}
                className="practices-table mt-2"
                scroll={{ x: 1100 }}
                bordered
                expandable={{
                    expandedRowRender: expandedRowRender,
                }}
            />
        </Flex>
    );
}
