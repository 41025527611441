import { Flex, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { fetchGroupOptions, fetchSelectedGroupUsers, fetchUserOptions } from '../helpers/index.config';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { useEnterModal } from '../../../hooks/useEnterModal';
import { SET_PRACTICE_RETRY } from '../../../redux-toolkit/saga/types';
import MultiSelect from '../../select/MultiSelect';

export default function GroupRetry({
    isOpen,
    setIsModalOpen,
}: {
    isOpen: boolean;
    setIsModalOpen: (val: boolean) => void;
}) {
    const dispatch = useAppDispatch();
    const { practiceId } = useParams();
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [selectedUsersByGroup, setSelectedUsersByGroup] = useState<{ label: string; value: string }[] | null>([]);
    useEnterModal(isOpen, handleSubmit);

    function handleSubmit() {
        dispatch({
            type: SET_PRACTICE_RETRY,
            payload: {
                practiceId: practiceId,
                userIds: selectedUserIds,
            },
        });
        setIsModalOpen(false);
        clearStates();
    }

    function handleCancel() {
        setIsModalOpen(false);
        clearStates();
    }

    function clearStates() {
        setSelectedGroupIds([]);
        setSelectedUserIds([]);
        setSelectedUsersByGroup(null);
    }

    function handleGroupChange(newValue: string[]) {
        setSelectedGroupIds(newValue);
    }

    function handleUserChange(newValue: string[]) {
        setSelectedUserIds(newValue);
    }

    useEffect(() => {
        const getSelectedGroupUsers = async () => {
            const { groupUsers, groupUserIds } = await fetchSelectedGroupUsers(selectedGroupIds);
            setSelectedUserIds(groupUserIds);
            setSelectedUsersByGroup(groupUsers);
        };
        getSelectedGroupUsers();
    }, [selectedGroupIds]);

    return (
        <Modal title="Select groups or users" open={isOpen} onCancel={handleCancel} onOk={handleSubmit} okText="Save">
            <Flex vertical gap="middle" style={{ marginTop: '20px' }}>
                <MultiSelect
                    placeholder="Select or search groups"
                    selectedIds={new Set(selectedGroupIds)}
                    handleChange={handleGroupChange}
                    fetchOptions={fetchGroupOptions}
                    size="large"
                />
                <MultiSelect
                    placeholder="Select or search users"
                    selectedIds={new Set(selectedUserIds)}
                    defaultOption={selectedUsersByGroup}
                    handleChange={handleUserChange}
                    fetchOptions={fetchUserOptions}
                    size="large"
                />
            </Flex>
        </Modal>
    );
}
