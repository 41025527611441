import { getAdmin, useGetAdminCols } from './helpers';

import { CustomTable } from '../../components/CustomTable';
import useCrudPermissions from '../../hooks/useCrudPermissions';
import adminService from '../../services/api/api-services/adminService';
import { AdminModal } from './components/AdminModal';

const Admin: React.FC = () => {
    const crudPermissions = useCrudPermissions('admin');

    return (
        <CustomTable
            title="Admin"
            service={{
                getById: (id) => adminService.getById(id),
                delete: (id) => adminService.deleteAdmin(id),
            }}
            fetchData={getAdmin}
            getColumns={useGetAdminCols}
            ModalComponent={AdminModal}
            permissions={crudPermissions}
        />
    );
};

export default Admin;
