import { matchPath } from 'react-router-dom';

export const isGroup = (
    item: NavigationMenuItem | NavigationMenuGroup | NavigationMenuDivider
): item is NavigationMenuGroup =>
    (item as NavigationMenuGroup).type === 'group' && Array.isArray((item as NavigationMenuGroup).children);

export const isMenuItem = (item: NavigationMenuItem | NavigationMenuDivider): item is NavigationMenuItem =>
    (item as NavigationMenuItem).onClick !== undefined;

export const getFilteredMenuItems = (
    menuItems: NavigationMenu,
    hasPermission: (perm: string) => boolean
): NavigationMenu =>
    menuItems
        .map((item) => {
            if (item.type === 'group') {
                const visibleChildren = item.children.filter(
                    (child) => !child.permission || hasPermission(child.permission)
                );
                return visibleChildren.length ? { ...item, children: visibleChildren } : null;
            }
            return item;
        })
        .filter(Boolean) as NavigationMenu;

export const getActiveKey = (menuItems: NavigationMenu, pathname: string): string =>
    menuItems.reduce<string | undefined>((foundKey, item) => {
        if (foundKey) return foundKey;

        if (isGroup(item)) {
            return (
                item.children.find(
                    (menuItem) => isMenuItem(menuItem) && matchPath(menuItem.path?.toString() + '/*', pathname)
                )?.key ?? foundKey
            );
        }
        return isMenuItem(item) && matchPath(item.path?.toString() + '/*', pathname) ? item.key : foundKey;
    }, undefined) || '';
