import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBoardState, IMobileViewPermissionsState } from '.';

const initialState: IBoardState = {
    basePath: '',
    viewType: '',
    apiPayloadType: '',
    addButtonLabel: '',
    tableData: [],
    filterFields: {
        filterBySort: true,
        filterBySearch: true,
        filterByColumn: true,
        filterByDate: true,
        showFilter: true,
    },
    dispatchKey: '',
    tableTotal: 0,
    selectOptions: [],
    paginationParams: { page: 1, limit: 10 },
    deleteKey: '',
    showHistory: false,
    canCreate: false,
    permissions: {
        canDelete: false,
        canUpdate: false,
        canReassign: false,
    },
};

const boardSlice = createSlice({
    name: 'board',
    initialState: initialState,
    reducers: {
        setBasePath: (state, action) => {
            state.basePath = action.payload;
        },
        setViewType: (state, action) => {
            state.viewType = action.payload;
        },
        setApiPayloadType: (state, action) => {
            state.apiPayloadType = action.payload;
        },
        setAddButtonLabel: (state, action) => {
            state.addButtonLabel = action.payload;
        },
        setBoardProps: (state, action) => {
            state.addButtonLabel = action.payload.addButtonLabel;
            state.basePath = action.payload.basePath;
            state.viewType = action.payload.viewType;
            state.apiPayloadType = action.payload.apiPayloadType;
            state.filterFields = action.payload.filterFields;
            state.dispatchKey = action.payload.dispatchKey;
            state.selectOptions = action.payload.selectOptions;
            state.deleteKey = action.payload.deleteKey;
            state.showHistory = action.payload.showHistory;
            state.canCreate = action.payload.canCreate;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setTableTotal: (state, action) => {
            state.tableTotal = action.payload;
        },
        setPaginationParams: (state, action) => {
            state.paginationParams = action.payload;
        },
        setMobileViewPermissions: (state, action: PayloadAction<{ permissions: IMobileViewPermissionsState }>) => {
            state.permissions = action.payload.permissions;
        },
    },
});

export const {
    setBasePath,
    setViewType,
    setApiPayloadType,
    setBoardProps,
    setTableData,
    setTableTotal,
    setPaginationParams,
    setMobileViewPermissions,
} = boardSlice.actions;
export default boardSlice.reducer;
