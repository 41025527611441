import { useEffect } from 'react';

export const useEnterModal = (isOpen: boolean, onSubmit: VoidFunction) => {
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                onSubmit();
            }
        };

        if (isOpen) {
            window.addEventListener('keypress', handleKeyPress);
            return () => window.removeEventListener('keypress', handleKeyPress);
        }
    }, [isOpen]);
};
