import './index.css';

import { Button, Col, MenuProps, Row } from 'antd';
import { CLONE_TO_LEFT, CLONE_TO_RIGHT } from '../../../redux-toolkit/saga/types';
import {
    clearTransferStates,
    setClonedSections,
    setTransferLeftSections,
    setTransferSelectedPracticeId,
    setTransferSelectedPracticeSections,
} from '../../../redux-toolkit';
import { faArrowDown, faArrowLeftLong, faArrowRightLong, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OptionsList from './OptionsList';
import OriginalList from './OriginalList';
import { fetchSelectedPracticeSections } from './index.config';
import { useParams } from 'react-router-dom';

const TransferApp = () => {
    const dispatch = useAppDispatch();
    const { practiceId: currentPracticeId } = useParams<{ practiceId: string }>();
    const [selectedItemsLeft, setSelectedItemsLeft] = useState<string[]>([]);
    const [selectedItemsRight, setSelectedItemsRight] = useState<string[]>([]);
    const [selectedPracticeId, setSelectedPracticeId] = useState<string | null>(null);
    const [isFetchingSections, setIsFetchingSections] = useState(false);
    const removeDublicate = (arr: any[]) => Array.from(new Set(arr));
    const {
        isMobile,
        clonedSections,
        currentSectionsById,
        transferLeftSections,
        transferSelectedPracticeId,
        transferSelectedPracticeSections,
    } = useAppSelector((state) => state.global);

    const handleTransferToRight = () => {
        const itemsToTransfer = selectedItemsLeft;
        const newSections = transferLeftSections.filter((section) => itemsToTransfer.includes(section._id));
        if (transferSelectedPracticeId) {
            dispatch(setClonedSections(removeDublicate([...clonedSections, ...newSections])));
        }
        setSelectedItemsLeft([]);
    };

    const handleTransferToLeft = () => {
        const itemsToTransfer = selectedItemsRight;
        dispatch(
            setTransferLeftSections(
                removeDublicate([
                    ...transferLeftSections,
                    ...clonedSections.filter((section) => itemsToTransfer.includes(section._id)),
                ])
            )
        );
        setSelectedItemsRight([]);
    };

    const handleSelectAllLeft = () => {
        setSelectedItemsLeft(transferLeftSections.map((section) => section._id));
    };

    const handleSelectAllRight = () => {
        setSelectedItemsRight(clonedSections.map((section) => section._id));
    };

    const handleInvertSelectionLeft = () => {
        const newSelected = transferLeftSections
            .map((section) => section._id)
            .filter((id) => !selectedItemsLeft.includes(id));
        setSelectedItemsLeft(newSelected);
    };

    const handleInvertSelectionRight = () => {
        const newSelected = clonedSections
            .map((section) => section._id)
            .filter((id) => !selectedItemsRight.includes(id));
        setSelectedItemsRight(newSelected);
    };

    async function handlePracticeChange(newValue: string) {
        if (newValue) {
            setIsFetchingSections(true);
            const sections = await fetchSelectedPracticeSections(newValue);
            dispatch(setClonedSections(sections));
            dispatch(setTransferSelectedPracticeSections(sections));
            setIsFetchingSections(false);
        } else {
            dispatch(setClonedSections([]));
        }
        setSelectedPracticeId(newValue);
        dispatch(setTransferSelectedPracticeId(newValue));
    }

    const HandleResetLeft = () => dispatch(setTransferLeftSections(currentSectionsById));

    const HandleResetRight = () => {
        dispatch(setClonedSections(transferSelectedPracticeSections));
    };

    const onMenuClick: MenuProps['onClick'] = (e) =>
        e.key == 'sa-1'
            ? handleSelectAllRight()
            : e.key == 'sa-2'
              ? handleSelectAllLeft()
              : e.key == 'is-2'
                ? handleInvertSelectionLeft()
                : handleInvertSelectionRight();

    const HandleSaveLeft = () => {
        dispatch({
            type: CLONE_TO_LEFT,
            payload: {
                sectionIds: transferLeftSections
                    .map((x) => x?._id)
                    .filter((x: any) => !currentSectionsById?.map((s) => s?._id).includes(x)),
                toPracticeId: currentPracticeId,
            },
        });
    };

    const HandleSaveRight = () => {
        const prevSections = transferSelectedPracticeSections?.map((x: any) => x?._id) || [];
        const newSections = clonedSections?.map((x: any) => x?._id).filter((_id: any) => !prevSections?.includes(_id));
        dispatch({
            type: CLONE_TO_RIGHT,
            payload: {
                sectionIds: newSections,
                toPracticeId: transferSelectedPracticeId,
            },
        });
    };

    useEffect(() => {
        return () => {
            dispatch(clearTransferStates());
        };
    }, [dispatch]);

    return (
        <Row gutter={[32, 16]} className="mt-15p">
            <OriginalList
                selectedItemsLeft={selectedItemsLeft}
                setSelectedItemsLeft={setSelectedItemsLeft}
                HandleSaveLeft={HandleSaveLeft}
                HandleResetLeft={HandleResetLeft}
                onMenuClick={onMenuClick}
            />
            <Col xs={24} sm={24} md={4} lg={4}>
                <div className="transfer-arrow-btns">
                    <Button
                        onClick={handleTransferToRight}
                        className="full-width"
                        icon={<FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRightLong} />}
                    />
                    <Button
                        onClick={handleTransferToLeft}
                        className="mt-10p  full-width arrow-left"
                        icon={<FontAwesomeIcon icon={isMobile ? faArrowUp : faArrowLeftLong} />}
                    />
                </div>
            </Col>
            <OptionsList
                selectedPracticeId={selectedPracticeId}
                handlePracticeChange={handlePracticeChange}
                isFetchingSections={isFetchingSections}
                selectedItemsRight={selectedItemsRight}
                setSelectedItemsRight={setSelectedItemsRight}
                HandleSaveRight={HandleSaveRight}
                HandleResetRight={HandleResetRight}
                onMenuClick={onMenuClick}
            />
        </Row>
    );
};

export default memo(TransferApp);
