import { createSlice } from '@reduxjs/toolkit';
import { IAssessmentsState } from '.';

const initialState: IAssessmentsState = {
    assessments: [],
    isCreateSuccess: false,
    assessmentData: null,
};

const assessmentsSlice = createSlice({
    name: 'assessments',
    initialState: initialState,
    reducers: {
        setAssessments: (state, action) => {
            state.assessments = action.payload;
        },
        setAssessment: (state, action) => {
            state.assessmentData = action.payload;
        },
        setIsCreateSuccess: (state, action) => {
            state.isCreateSuccess = action.payload;
        },
    },
});

export const { setIsCreateSuccess, setAssessment, setAssessments } = assessmentsSlice.actions;
export default assessmentsSlice.reducer;
