import { createSlice } from '@reduxjs/toolkit';

export interface IGlobalStates {
    questionById: any;
}
const initialState: IGlobalStates = {
    questionById: null,
};

const questionSlice = createSlice({
    name: 'questions',
    initialState: initialState,
    reducers: {
        setQuestionById: (state, action) => {
            state.questionById = action.payload;
        },
    },
});

export const { setQuestionById } = questionSlice.actions;
export default questionSlice.reducer;
