import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import PopConfirmApp from '../../../components/button/PopConfirmApp';
import { IPracticeTableDataConfig } from '../../../components/tableView';
import roleService from '../../../services/api/api-services/roleService';

export interface RoleValues {
    _id: string;
    name: string;

    user: boolean;
    userCreate: string;
    userUpdate: boolean;
    userDelete: boolean;

    group: boolean;
    groupCreate: boolean;
    groupUpdate: boolean;
    groupDelete: boolean;

    role: boolean;
    roleCreate: boolean;
    roleUpdate: boolean;
    roleDelete: boolean;

    admin: boolean;
    adminCreate: boolean;
    adminUpdate: boolean;
    adminDelete: boolean;

    question: boolean;
    questionCreate: boolean;
    questionUpdate: boolean;
    questionDelete: boolean;

    section: boolean;
    sectionCreate: boolean;
    sectionUpdate: boolean;
    sectionDelete: boolean;

    practice: boolean;
    practiceCreate: boolean;
    practiceUpdate: boolean;
    practiceDelete: boolean;

    levelCheck: boolean;
    levelCheckCreate: boolean;
    levelCheckUpdate: boolean;
    levelCheckDelete: boolean;
    levelCheckResult: boolean;

    homework: boolean;
    homeworkCreate: boolean;
    homeworkUpdate: boolean;
    homeworkDelete: boolean;
    homeworkResult: boolean;

    exam: boolean;
    examCreate: boolean;
    examUpdate: boolean;
    examDelete: boolean;
    examResult: boolean;

    finalExam: boolean;
    finalExamCreate: boolean;
    finalExamUpdate: boolean;
    finalExamDelete: boolean;
    finalExamResult: boolean;

    permissions?: string[];
}

export const useGetRoleCols = (
    handleEdit: (id: string) => void,
    handleDelete: (id: string) => void,
    canEdit: boolean,
    canDelete: boolean
) => {
    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 800,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: unknown, record: IPracticeTableDataConfig) => (
                <div className="d-flex-even">
                    <Button disabled={!canEdit} icon={<EditOutlined />} onClick={() => handleEdit(record._id)}></Button>
                    <PopConfirmApp
                        title="Are you sure you want to delete this role?"
                        description="This action cannot be undone."
                        confirm={() => handleDelete(record?._id)}
                        children={<Button disabled={!canDelete} icon={<DeleteOutlined />} danger></Button>}
                    />
                </div>
            ),
            width: 200,
        },
    ];
};

export async function getRoles(currentPage: number = 0, pageSize: number = 10, search?: string) {
    const api = roleService;
    let result;
    if (search) {
        result = api.getRoles({ page: currentPage, limit: pageSize, search: search });
    } else {
        result = api.getRoles({ page: currentPage, limit: pageSize });
    }
    return result.then((response) => response);
}

export const useGetRoleValues = (values: RoleValues) => {
    return {
        name: values.name ?? '',

        user: values.user ?? false,
        userCreate: values.userCreate ?? false,
        userUpdate: values.userUpdate ?? false,
        userDelete: values.userDelete ?? false,

        group: values.group ?? false,
        groupCreate: values.groupCreate ?? false,
        groupUpdate: values.groupUpdate ?? false,
        groupDelete: values.groupDelete ?? false,

        admin: values.admin ?? false,
        adminCreate: values.adminCreate ?? false,
        adminUpdate: values.adminUpdate ?? false,
        adminDelete: values.adminDelete ?? false,

        role: values.role ?? false,
        roleCreate: values.roleCreate ?? false,
        roleUpdate: values.roleUpdate ?? false,
        roleDelete: values.roleDelete ?? false,

        question: values.question ?? false,
        questionCreate: values.questionCreate ?? false,
        questionUpdate: values.questionUpdate ?? false,
        questionDelete: values.questionDelete ?? false,

        section: values.section ?? false,
        sectionCreate: values.sectionCreate ?? false,
        sectionUpdate: values.sectionUpdate ?? false,
        sectionDelete: values.sectionDelete ?? false,

        practice: values.practice ?? false,
        practiceCreate: values.practiceCreate ?? false,
        practiceUpdate: values.practiceUpdate ?? false,
        practiceDelete: values.practiceDelete ?? false,

        levelCheck: values.levelCheck ?? false,
        levelCheckCreate: values.levelCheckCreate ?? false,
        levelCheckUpdate: values.levelCheckUpdate ?? false,
        levelCheckDelete: values.levelCheckDelete ?? false,
        levelCheckResult: values.levelCheckResult ?? false,

        homework: values.homework ?? false,
        homeworkCreate: values.homeworkCreate ?? false,
        homeworkUpdate: values.homeworkUpdate ?? false,
        homeworkDelete: values.homeworkDelete ?? false,
        homeworkResult: values.homeworkResult ?? false,

        exam: values.exam ?? false,
        examCreate: values.examCreate ?? false,
        examUpdate: values.examUpdate ?? false,
        examDelete: values.examDelete ?? false,
        examResult: values.examResult ?? false,

        finalExam: values.finalExam ?? false,
        finalExamCreate: values.finalExamCreate ?? false,
        finalExamUpdate: values.finalExamUpdate ?? false,
        finalExamDelete: values.finalExamDelete ?? false,
        finalExamResult: values.finalExamResult ?? false,
    };
};
