import { Button, Checkbox, Col, Dropdown, Flex, List, MenuProps } from 'antd';
import { fetchPracticeOptions, rightOptions } from './index.config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleSelect from '../../select/SingleSelect';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../app/hooks';

interface Props {
    selectedPracticeId: string | null;
    handlePracticeChange: (val: string) => void;
    isFetchingSections: boolean;
    selectedItemsRight: string[];
    setSelectedItemsRight: (val: any[]) => void;
    HandleSaveRight: () => void;
    HandleResetRight: () => void;
    onMenuClick: MenuProps['onClick'];
}

export default function OptionsList({
    selectedPracticeId,
    handlePracticeChange,
    isFetchingSections,
    selectedItemsRight,
    setSelectedItemsRight,
    HandleSaveRight,
    HandleResetRight,
    onMenuClick,
}: Props) {
    const { clonedSections, transferSelectedPracticeSections } = useAppSelector((state) => state.global);
    const renderSaveButton = () => {
        const disabled =
            clonedSections?.length == transferSelectedPracticeSections?.length ||
            clonedSections?.length == 0 ||
            isFetchingSections;

        return (
            <Button
                type="primary"
                className="dyn-submit-btn transfer-save-btn"
                onClick={HandleSaveRight}
                disabled={disabled}
            >
                Save
            </Button>
        );
    };

    return (
        <Col xs={24} sm={24} md={10} lg={10} span={10}>
            <Flex justify="space-between" align="center" gap={10} className="mb-10p">
                <div style={{ width: '80%' }}>
                    <SingleSelect
                        placeholder="Search or select practice"
                        selectedId={selectedPracticeId}
                        handleChange={handlePracticeChange}
                        fetchOptions={fetchPracticeOptions}
                        loading={isFetchingSections}
                    />
                </div>
                <Dropdown.Button
                    style={{ width: 'auto' }}
                    trigger={['click']}
                    menu={{ items: rightOptions, onClick: onMenuClick }}
                    onClick={HandleResetRight}
                >
                    <FontAwesomeIcon icon={faArrowRotateRight} className="mr-10p" />
                    Reset
                </Dropdown.Button>
            </Flex>
            <List
                className="transfer-list-box"
                bordered
                dataSource={clonedSections}
                renderItem={(item) => (
                    <List.Item>
                        <Checkbox
                            className={
                                transferSelectedPracticeSections?.map((section: any) => section._id).includes(item?._id)
                                    ? ''
                                    : ' transfered-section '
                            }
                            checked={selectedItemsRight.includes(item._id)}
                            onChange={() => {
                                if (selectedItemsRight.includes(item._id)) {
                                    setSelectedItemsRight(selectedItemsRight.filter((id) => id !== item._id));
                                } else {
                                    setSelectedItemsRight([...selectedItemsRight, item._id]);
                                }
                            }}
                        >
                            {item.name}
                        </Checkbox>
                    </List.Item>
                )}
            />
            {renderSaveButton()}
        </Col>
    );
}
