export const GET_USERS = 'GET_USERS';
export const GET_TOKEN = 'GET_TOKEN';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const GET_PRACTICE = 'GET_PRACTICE';
export const GET_PRACTICES = 'GET_PRACTICES';
export const CREATE_PRACTICE = 'CREATE_PRACTICE';
export const UPDATE_PRACTICE = 'UPDATE_PRACTICE';
export const DELETE_PRACTICE = 'DELETE_PRACTICE';

export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';

export const CREATE_SECTION = 'CREATE_SECTION';
export const CURRENT_SECTIONS = 'CURRENT_SECTIONS';
export const WITH_SECTIONS = 'WITH_SECTIONS';
export const ANY_SECTIONS = 'ANY_SECTIONS';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const CLONE_TO_LEFT = 'CLONE_TO_LEFT';
export const CLONE_TO_RIGHT = 'CLONE_TO_RIGHT';
export const REORDER_SECTIONS = 'REORDER_SECTIONS';

export const GET_PRACTICE_HISTORY = 'GET_PRACTICE_HISTORY';
export const SET_PRACTICE_RETRY = 'GET_PRACTICE_RETRY';

export const GET_ADMINS = 'GET_ADMINS';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';

export const CREATE_FOLDER = 'CREATE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const ADD_FOLDER_ITEMS = 'ADD_FOLDER_ITEMS';
export const REMOVE_FOLDER_ITEMS = 'REMOVE_FOLDER_ITEMS';

export const GET_QUESTION = 'GET_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const GET_QUESTION_BY_ID = 'GET_QUESTION_BY_ID';

export const GET_SECTIONS = 'GET_SECTIONS';
export const CREATE_SECTIONS = 'CREATE_SECTIONS';
export const DELETE_SECTIONS = 'DELETE_SECTIONS';
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS';
export const GET_SECTION_BY_ID = 'GET_SECTION_BY_ID';

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
