import { createSlice } from '@reduxjs/toolkit';
interface QuestionsValidationState {
    validations: boolean[];
}
const initialState: QuestionsValidationState = {
    validations: [],
};

// has true value if question is valid, false value if question is invalid

const questionsValidationSlice = createSlice({
    name: 'questionsValidation',
    initialState,
    reducers: {
        updateValidations: (state, action) => {
            state.validations = action.payload;
        },
        clearValidations: () => {
            return {
                validations: [],
            };
        },
    },
});
export const { updateValidations, clearValidations } = questionsValidationSlice.actions;
export default questionsValidationSlice.reducer;
