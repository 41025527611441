import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';
import global from '../redux-toolkit/index';
import rootSaga from '../redux-toolkit/saga/rootSaga';
import assessmentsReducer from '../redux-toolkit/slices/assessmentSlice';
import boardReducer from '../redux-toolkit/slices/boardSlice';
import folderReducer from '../redux-toolkit/slices/folderSlice';
import groupReducer from '../redux-toolkit/slices/groupSlice';
import questionsReducer from '../redux-toolkit/slices/questionsSlice';
import questionsValidationReducer from '../redux-toolkit/slices/questionsValidationSlice';
import sectionsReducer from '../redux-toolkit/slices/sectionsSlice';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
    reducer: {
        global,
        group: groupReducer,
        folders: folderReducer,
        board: boardReducer,
        questionsValidation: questionsValidationReducer,
        questions: questionsReducer,
        sections: sectionsReducer,
        assessments: assessmentsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
