import { IPracticeResponse } from '.';

import { BaseService } from '../baseService';

class SectionsService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/sections');
    }

    async getSections(data: any) {
        const response = await this.get(data);
        return response;
    }
    async createSection(data: any) {
        const response = await this.create(data);
        return response;
    }
    async deleteSection(id: string) {
        const response = await this.delete(id);
        return response;
    }
    async getSectionById(id: string) {
        const response = await this.getById(id);
        return response;
    }
    async updateSection(id: string, body: any) {
        const response = await this.update(id, body);
        return response;
    }
    async getSectionQuestions(sectionId: string) {
        const response = await this.get('', `/${sectionId}/questions`);
        return Array.isArray(response?.result?.data) ? response.result.data : [];
    }
}

export default new SectionsService();
