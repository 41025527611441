export const getErrorMessage = (action: string, entity?: string, reload: boolean = false) => {
    const helperMessage = reload ? 'Please reload the page' : 'Please try again';
    
    if (!entity) {
        return `Failed to ${action}. ${helperMessage}`;
    }
    
    entity = entity?.toLowerCase();
    const excludeThe = entity?.endsWith('s') || entity == 'homework';
    const mainMessage = `Failed to ${action} ${excludeThe ? entity : `the ${entity}`}`;

    return `${mainMessage}. ${helperMessage}`;
}