import { Layout, message, Spin, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import Navbar from './bars/Navbar';
import Sidebar from './bars/Sidebar';
import './index.css';

const { Content } = Layout;

export default function MainLayout(props: ILayoutProps) {
    const { isMobile } = useAppSelector((state) => state.global);
    const [collapsed, setCollapsed] = useState(isMobile);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const {
        loaders: { layoutLoad },
        notification,
    } = useAppSelector((state) => state.global);

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (notification.type === 'success' || notification.type === 'error') {
            messageApi.open(notification);
        }
    }, [notification, messageApi]);

    return (
        <>
            {contextHolder}
            <Spin spinning={layoutLoad}>
                <Layout>
                    <Navbar collapsed={collapsed} HandleCollapse={() => setCollapsed(!collapsed)} />
                    <Layout>
                        <Sidebar collapsed={collapsed} onClose={() => setCollapsed(!collapsed)} />
                        <Layout>
                            <Content
                                className={'main-layout-content'}
                                style={{
                                    background: colorBgContainer,
                                    borderRadius: borderRadiusLG,
                                }}
                            >
                                {props.children}
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </Spin>
        </>
    );
}
