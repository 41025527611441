import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { setLoaders, setNotification } from '..';
import queryClient from '../../app/queryClientConfig';
import { IPracticeParams, IPracticeResponse } from '../../services/api/api-services';
import sectionsService from '../../services/api/api-services/sectionsService';
import { getErrorMessage } from '../../utils/errorMessages';
import { setTableData, setTableTotal } from '../slices/boardSlice';
import { setSectionDataById, setSections } from '../slices/sectionsSlice';
import { CREATE_SECTIONS, DELETE_SECTIONS, GET_SECTIONS, GET_SECTION_BY_ID, UPDATE_SECTIONS } from './types';

function* _createSections({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        yield sectionsService.createSection(payload);
        yield put(setLoaders({ layoutLoad: false }));
        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'sections'),
            })
        );
    }
}

function* _getSections({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    const { type, ...params } = payload;
    try {
        const response: IPracticeResponse = yield sectionsService.getSections(params);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setSections(response?.result?.data));
        yield put(setTableData(response?.result?.data));
        yield put(setTableTotal(response?.result?.total));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'sections', true),
            })
        );
        yield put(setTableData([]));
        yield put(setTableTotal(0));
    }
}

function* _deleteSection({ payload }: PayloadAction<{ id: string; getParams: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        yield sectionsService.deleteSection(payload.id);
        yield put({
            type: GET_SECTIONS,
            payload: payload.getParams,
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully deleted' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'section'),
            })
        );
    }
}

function* _getSectionById({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield sectionsService.getSectionById(payload.id);
        yield put(setSectionDataById(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
    }
}

function* _updateSections({ payload }: PayloadAction<{ id: string; body: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        yield sectionsService.updateSection(payload.id, payload.body);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'section'),
            })
        );
    }
}

function* sectionsSaga() {
    yield takeEvery(GET_SECTIONS, _getSections);
    yield takeEvery(CREATE_SECTIONS, _createSections);
    yield takeEvery(GET_SECTION_BY_ID, _getSectionById);
    yield takeEvery(DELETE_SECTIONS, _deleteSection);
    yield takeEvery(UPDATE_SECTIONS, _updateSections);
}
export default sectionsSaga;
