import { useEffect, useState } from 'react';

import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useRemoveTags } from '../../hooks/useRemoveTags';
import { debounce } from '../../utils/debounce';

const PAGE_SIZE = 10;

interface MultiSelectProps {
    fetchOptions: (payload: any) => Promise<{ data: { label: string; value: string }[]; total: number }>;
    defaultOption?: { label: string; value: string }[] | null;
    selectedIds?: Set<string> | null;
    handleChange?: (newValue: string[]) => void;
    placeholder?: string;
    size?: SizeType;
    maxCount?: number;
    loading?: boolean;
    isLong?: boolean;
    customLimit?: number;
    isResponsive?: boolean;
}

export default function MultiSelect({
    fetchOptions,
    placeholder,
    defaultOption,
    handleChange,
    selectedIds,
    size,
    maxCount,
    loading,
    isLong,
    customLimit,
    isResponsive,
}: MultiSelectProps) {
    const [groupOptions, setGroupOptions] = useState<{ label: string; value: string }[]>([]);
    const [isFetchingOptions, setIsFetchingOptions] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreGroups, setHasMoreGroups] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const makeUniqueOptions = (options: { label: string; value: string }[]) => {
        const set = new Set();
        const res: { label: string; value: string }[] = [];
        options.forEach((option: any) => {
            if (!set.has(option.value)) {
                set.add(option.value);
                res.push(option);
            }
        });
        return res;
    };

    const debouncedFetchGroupOptions = debounce(async (value: string) => {
        setSearchValue(value);
        setIsFetchingOptions(true);
        const payload = {
            page: currentPage,
            limit: customLimit ?? PAGE_SIZE,
            search: value,
        };
        const { data, total } = await fetchOptions(payload);
        setGroupOptions(data);
        setHasMoreGroups(data?.length < total);
        setIsFetchingOptions(false);
    }, 300);

    function handleGroupSearch(value: string) {
        setCurrentPage(1);
        debouncedFetchGroupOptions(value);
    }

    const handleGroupScroll = async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { target } = e;
        if (
            target instanceof HTMLElement &&
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            hasMoreGroups &&
            !isFetchingOptions
        ) {
            setIsFetchingOptions(true);
            const nextPage = currentPage + 1;
            const payload = {
                page: currentPage,
                limit: customLimit ?? PAGE_SIZE,
                search: searchValue,
            };
            const { data, total } = await fetchOptions(payload);
            setGroupOptions((prevOptions) => {
                return makeUniqueOptions([...prevOptions, ...data]);
            });
            setCurrentPage(nextPage);
            setHasMoreGroups(data?.length < total);
            setIsFetchingOptions(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const { data, total } = await fetchOptions({ page: 1, limit: customLimit ?? PAGE_SIZE });
            if (defaultOption) {
                setGroupOptions(makeUniqueOptions([...data, ...defaultOption]));
            } else {
                setGroupOptions(data);
            }
            setHasMoreGroups(data?.length < total);
        };
        fetchData();
    }, [defaultOption]);

    return (
        <>
            <Select
                mode="multiple"
                maxCount={maxCount}
                allowClear
                size={size ?? 'middle'}
                filterOption={false}
                style={{ width: '100%' }}
                placeholder={placeholder || 'Select or search options'}
                options={groupOptions.map((item) => {
                    return {
                        label: useRemoveTags(item.label),
                        value: item.value,
                    };
                })}
                onSearch={handleGroupSearch}
                onChange={handleChange}
                value={Array.from(selectedIds || [])}
                onPopupScroll={handleGroupScroll}
                notFoundContent={isFetchingOptions ? 'Searching...' : 'No groups found'}
                loading={loading}
                listHeight={isLong ? 502 : 246}
                maxTagCount={isResponsive ? 'responsive' : undefined}
            />
        </>
    );
}
