import { IFolderParams, IFoldersParams, IPracticeResponse } from '.';
import { BaseService } from '../baseService';

class FolderService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/folders');
    }

    async getFolders(params: IFoldersParams) {
        const response = await this.get(params, `/list/${params.type}`);
        return response;
    }
    async getFolder(params: IFolderParams) {
        const response = await this.getById(params.id, `/folders/${params.type}`, {
            page: params?.page,
            limit: params?.limit,
        });
        return response;
    }
    async createFolder(data: { name: string; parentId?: string }, type: string) {
        const response = await this.create(data, `/create/${type}`);
        return response;
    }
    async addFolderItems(data: { items: string[] }, params: { type: string; id: string }) {
        const response = await this.create(data, `/add-items/${params.type}/${params.id}`);
        return response;
    }
    async removeFolderItems(data: { items: string[] }, params: { type: string; id: string }) {
        const response = await this.create(data, `/remove-items/${params.type}/${params.id}`);
        return response;
    }
    async moveFolders(params: { type: string; id: string; toId: string }) {
        const response = await this.get('', `/move/${params.type}/${params.id}/${params.toId}`);
        return response;
    }
    async reorderFolderItems(
        data: { items: { id: string; position: number }[] },
        params: { type: string; id: string }
    ) {
        const response = await this.post(data, `/reposition/${params.type}/${params.id}`);
        return response;
    }
    async updateFolder(data: { name: string }, params: { type: string; id: string }) {
        const response = await this.update(params.id, data, `/folders/update/${params.type}`);
        return response;
    }
    async deleteFolder(params: { id: string; type: string }) {
        const response = await this.delete(params.id, `folders/delete/${params.type}`);
        return response;
    }
}

export default new FolderService();
