import { put, takeEvery } from 'redux-saga/effects';
import { setLoaders, setNotification } from '..';
import {
    IAddFolderItemPayload,
    ICreateFolderPayload,
    IPracticeResponse,
    IUpdateFolderPayload,
} from '../../services/api/api-services';
import { ADD_FOLDER_ITEMS, CREATE_FOLDER, DELETE_FOLDER, REMOVE_FOLDER_ITEMS, UPDATE_FOLDER } from './types';

import { PayloadAction } from '@reduxjs/toolkit';
import queryClient from '../../app/queryClientConfig';
import folderService from '../../services/api/api-services/folderService';
import { getErrorMessage } from '../../utils/errorMessages';

function* _createFolder({ payload }: PayloadAction<ICreateFolderPayload>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        let data = { name: '' };
        if (!payload.data?.parentId) {
            data = {
                name: payload.data.name,
            };
        } else {
            data = payload.data;
        }
        const response: IPracticeResponse = yield folderService.createFolder(data, payload.type);
        queryClient.invalidateQueries({ queryKey: ['folders'] });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'folder'),
            })
        );
    }
}

function* _deleteFolder({ payload }: PayloadAction<{ type: string; id: string; parentId: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield folderService.deleteFolder(payload);
        queryClient.invalidateQueries({ queryKey: ['folders'] });
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'folder'),
            })
        );
    }
}

function* _updateFolder({ payload }: PayloadAction<IUpdateFolderPayload>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield folderService.updateFolder(payload.data, payload.params);
        queryClient.invalidateQueries({ queryKey: ['folders'] });
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'folder'),
            })
        );
    }
}

function* _addFolderItems({ payload }: PayloadAction<IAddFolderItemPayload>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield folderService.addFolderItems(payload.data, payload.params);
        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('add', 'item'),
            })
        );
    }
}

function* _removeFolderItems({ payload }: PayloadAction<IAddFolderItemPayload>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield folderService.removeFolderItems(payload.data, payload.params);
        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('add', 'item'),
            })
        );
    }
}

function* folderSaga() {
    yield takeEvery(CREATE_FOLDER, _createFolder);
    yield takeEvery(DELETE_FOLDER, _deleteFolder);
    yield takeEvery(UPDATE_FOLDER, _updateFolder);
    yield takeEvery(ADD_FOLDER_ITEMS, _addFolderItems);
    yield takeEvery(REMOVE_FOLDER_ITEMS, _removeFolderItems);
}

export default folderSaga;
