export const rolePermissions = [
    { label: 'User', value: 'user' },
    { label: 'User Create', value: 'userCreate' },
    { label: 'User Update', value: 'userUpdate' },
    { label: 'User Delete', value: 'userDelete' },

    { label: 'Group', value: 'group' },
    { label: 'Group Create', value: 'groupCreate' },
    { label: 'Group Update', value: 'groupUpdate' },
    { label: 'Group Delete', value: 'groupDelete' },

    { label: 'Admin', value: 'admin' },
    { label: 'Admin Create', value: 'adminCreate' },
    { label: 'Admin Update', value: 'adminUpdate' },
    { label: 'Admin Delete', value: 'adminDelete' },

    { label: 'Role', value: 'role' },
    { label: 'Role Create', value: 'roleCreate' },
    { label: 'Role Update', value: 'roleUpdate' },
    { label: 'Role Delete', value: 'roleDelete' },

    { label: 'Question', value: 'question' },
    { label: 'Question Create', value: 'questionCreate' },
    { label: 'Question Update', value: 'questionUpdate' },
    { label: 'Question Delete', value: 'questionDelete' },

    { label: 'Section', value: 'section' },
    { label: 'Section Create', value: 'sectionCreate' },
    { label: 'Section Update', value: 'sectionUpdate' },
    { label: 'Section Delete', value: 'sectionDelete' },

    { label: 'Practice', value: 'practice' },
    { label: 'Practice Create', value: 'practiceCreate' },
    { label: 'Practice Update', value: 'practiceUpdate' },
    { label: 'Practice Delete', value: 'practiceDelete' },

    { label: 'Level Check', value: 'levelCheck' },
    { label: 'Level Check Create', value: 'levelCheckCreate' },
    { label: 'Level Check Update', value: 'levelCheckUpdate' },
    { label: 'Level Check Delete', value: 'levelCheckDelete' },
    { label: 'Level Check Result', value: 'levelCheckResult' },

    { label: 'Homework', value: 'homework' },
    { label: 'Homework Create', value: 'homeworkCreate' },
    { label: 'Homework Update', value: 'homeworkUpdate' },
    { label: 'Homework Delete', value: 'homeworkDelete' },
    { label: 'Homework Result', value: 'homeworkResult' },

    { label: 'Exam', value: 'exam' },
    { label: 'Exam Create', value: 'examCreate' },
    { label: 'Exam Update', value: 'examUpdate' },
    { label: 'Exam Delete', value: 'examDelete' },
    { label: 'Exam Result', value: 'examResult' },

    { label: 'Last Dance', value: 'finalExam' },
    { label: 'Last Dance Create', value: 'finalExamCreate' },
    { label: 'Last Dance Update', value: 'finalExamUpdate' },
    { label: 'Last Dance Delete', value: 'finalExamDelete' },
    { label: 'Last Dance Result', value: 'finalExamResult' },
];
