import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { GET_PERMISSIONS } from '../redux-toolkit/saga/types';

export default function useFetchPermissions() {
    const dispatch = useAppDispatch();
    const isAuthenticated = localStorage.getItem('auth');

    useEffect(() => {
        if (isAuthenticated) {
            dispatch({
                type: GET_PERMISSIONS,
            });
        }
    }, [isAuthenticated]);
}
