import { useAppSelector } from '../app/hooks';
import { useMemo } from 'react';

export default function usePermissions() {
    const { permissions } = useAppSelector((state) => state.global);

    const hasPermission = (key: string): boolean => {
        return Boolean(permissions?.[key]);
    };

    return useMemo(() => ({ hasPermission }), [permissions]);
}
