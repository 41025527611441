import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function NoPermission() {
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you don't have permission to this page."
            extra={
                <Button type="primary" onClick={() => navigate('/dashboard')}>
                    Back Home
                </Button>
            }
        />
    );
}
