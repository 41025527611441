import { LinkOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Table, TableColumnsType } from 'antd';
import { ExpandedDataConfig, IHistoryTableDataConfig } from '..';

import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { SET_PRACTICE_RETRY } from '../../../redux-toolkit/saga/types';
import groupService from '../../../services/api/api-services/groupService';
import userService from '../../../services/api/api-services/userService';
import { formatDateToReadable } from '../../../utils/formatDate';

export const HistoryColumns = () => {
    const dispatch = useAppDispatch();
    const { practiceId } = useParams();

    const handleStudentResult = useCallback((record: IHistoryTableDataConfig) => {
        const baseUrl = process.env.REACT_APP_STUDENT_SITE_URL;
        const token = localStorage.getItem('auth');
        const link = `${baseUrl}result?userId=${record.user._id}&practiceId=${record._id}&token=${token}`;
        window.open(link, '_blank');
    }, []);

    const handleRedo = useCallback((record: IHistoryTableDataConfig) => {
        dispatch({
            type: SET_PRACTICE_RETRY,
            payload: {
                practiceId: practiceId,
                userIds: [record?.user._id],
            },
        });
    }, []);

    const columns: TableColumnsType<any> = [
        {
            key: 'user.name',
            title: 'Name',
            dataIndex: 'name',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <>
                    <p>{record?.user?.name}</p>
                </>
            ),
        },
        {
            key: 'user.phone',
            title: 'Phone number',
            dataIndex: 'name',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <>
                    <p>{record?.user?.phone}</p>
                </>
            ),
        },
        {
            key: 'startedAt',
            title: 'Started Date',
            dataIndex: 'startedAt',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <>{record?.startedAt ? <p>{formatDateToReadable(record?.startedAt)}</p> : <p>-</p>}</>
            ),
        },
        {
            key: 'finishedAt',
            title: 'Finished Date',
            dataIndex: 'finishedAt',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <>{record?.finishedAt ? <p>{formatDateToReadable(record?.finishedAt)}</p> : <p>-</p>}</>
            ),
        },
        {
            key: 'state',
            title: 'State',
            dataIndex: 'state',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <>
                    <p>{record?.state.charAt(0).toUpperCase() + record?.state.slice(1)}</p>
                </>
            ),
            width: 100,
        },
        {
            key: 'total',
            title: 'Total',
            dataIndex: 'total',
            width: 80,
        },
        {
            key: 'right',
            title: 'Right',
            dataIndex: 'right',
            width: 80,
        },
        {
            key: 'wrong',
            title: 'Wrong',
            dataIndex: 'wrong',
            width: 80,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: any, record: IHistoryTableDataConfig) => (
                <div className="d-flex-even">
                    <Button icon={<LinkOutlined />} onClick={() => handleStudentResult(record)}></Button>
                    <Button icon={<RedoOutlined />} onClick={() => handleRedo(record)}></Button>
                </div>
            ),
        },
    ];

    return columns;
};

const expandColumns: TableColumnsType<any> = [
    {
        key: 'name',
        title: 'Section',
        dataIndex: 'name',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{record?.name}</p>
            </>
        ),
    },
    {
        key: 'startedAt',
        title: 'Started Date',
        dataIndex: 'startedAt',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{formatDateToReadable(record?.startedAt)}</p>
            </>
        ),
    },
    {
        key: 'finishedAt',
        title: 'Finished Date',
        dataIndex: 'finishedAt',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{formatDateToReadable(record?.finishedAt)}</p>
            </>
        ),
    },
    {
        key: 'total',
        title: 'Total',
        dataIndex: 'total',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{record?.total}</p>
            </>
        ),
    },
    {
        key: 'right',
        title: 'Right',
        dataIndex: 'right',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{record?.right}</p>
            </>
        ),
    },
    {
        key: 'wrong',
        title: 'Wrong',
        dataIndex: 'wrong',
        render: (_: any, record: ExpandedDataConfig) => (
            <>
                <p>{record?.wrong}</p>
            </>
        ),
    },
];

export const expandedRowRender = (record: any) => {
    return <Table rowKey={'_id'} columns={expandColumns} dataSource={record?.sections} pagination={false} />;
};

export const fetchGroupOptions = async (params?: any) => {
    const response = await groupService.getGroups(params);
    const groupsData = response?.result?.data;
    const total = response?.result?.total;
    const selectOptions = groupsData?.map((group: any) => {
        return {
            label: group?.name,
            value: group?._id,
        };
    });
    return { data: selectOptions, total };
};

export const fetchUserOptions = async (params?: any) => {
    const response = await userService.getUsers(params);
    const userData = response?.result?.data;
    const total = response?.result?.total;
    const selectOptions = userData?.map((user: any) => {
        return {
            label: `${user?.name} ${user?.phone ?? ''}`,
            value: user?._id,
        };
    });
    return { data: selectOptions, total };
};

export async function fetchSelectedGroupUsers(selectedGroupIds: string[]) {
    const userPromises = selectedGroupIds.map(async (groupId: any) => {
        const res = await groupService.getById(groupId);
        const options = res.result.users?.map((group: any) => {
            return {
                label: group?.name,
                value: group?._id,
            };
        });
        return { users: options, userIds: res.result.userIds };
    });

    const resolvedUsers = await Promise.all(userPromises);
    const groupUsers = resolvedUsers.flatMap((res) => res.users);
    const groupUserIds = resolvedUsers.flatMap((res) => res.userIds);
    return { groupUsers, groupUserIds };
}

export async function fetchSelectedGroup(groupId: string) {
    try {
        const res = await groupService.getById(groupId);
        return { label: res?.result?.name, value: res?.result?._id };
    } catch {}
}
