import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import PopConfirmApp from '../../../components/button/PopConfirmApp';
import { IPracticeTableDataConfig } from '../../../components/tableView';
import { setNotification } from '../../../redux-toolkit';
import groupService from '../../../services/api/api-services/groupService';
import userService from '../../../services/api/api-services/userService';

interface User {
    _id: string;
    name: string;
    phone: string;
}
export const useGetCols = (
    handleEdit: (id: string) => void,
    handleDelete: (id: string) => void,
    canEdit: boolean,
    canDelete: boolean
) => {
    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 800,
        },
        {
            title: 'Users',
            dataIndex: 'userIds.length',
            key: 'userIds.length',
            render: (_: any, rec: any) => rec.userIds.length || 0,
            width: 400,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: unknown, record: IPracticeTableDataConfig) => (
                <div className="d-flex-even">
                    <Button disabled={!canEdit} icon={<EditOutlined />} onClick={() => handleEdit(record._id)}></Button>
                    <PopConfirmApp
                        title="Are you sure you want to delete this group?"
                        description="This action cannot be undone."
                        confirm={() => handleDelete(record?._id)}
                        children={<Button disabled={!canDelete} icon={<DeleteOutlined />} danger></Button>}
                    />
                </div>
            ),
            width: 200,
        },
    ];
};

export async function getGroups(currentPage: number = 0, pageSize: number = 10, search: string) {
    const api = groupService;
    let result;
    if (search) {
        result = api.getGroups({ page: currentPage, limit: pageSize, search: search });
    } else {
        result = api.getGroups({ page: currentPage, limit: pageSize });
    }
    return result.then((response) => response);
}

export const fetchGroupUserOptions = async (payload: { [key: string]: any }) => {
    try {
        const response = await userService.getUsers(payload);
        if (response && response.result && Array.isArray(response.result.data)) {
            return {
                data: response.result.data.map((user: User) => ({
                    label: `${user.name} ${user.phone ? user.phone : ''}`,
                    value: user._id,
                })),
                total: response.result.total || 0,
            };
        } else {
            setNotification({
                type: 'error',
                content: 'Invalid or no response data',
            });
            return { data: [], total: 0 };
        }
    } catch (error) {
        setNotification({
            type: 'error',
            content: error instanceof Error ? error.message : 'An error occurred',
        });
        return { data: [], total: 0 };
    }
};

export function updateGroupById(id: string, data: any) {
    return groupService.updateGroup(id, data);
}
