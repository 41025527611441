import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { IHistory } from '.';
import { GET_PRACTICE_HISTORY } from '../../redux-toolkit/saga/types';
import HistoryTable from './components/HistoryTable';
import { fetchSelectedGroup } from './helpers/index.config';

export default function History({ getDispatchKey, practiceType, basePath }: IHistory) {
    const { practiceId } = useParams();
    const { practiceHistory, updatedPractice } = useAppSelector((state) => state.global);
    const { assessmentData } = useAppSelector((state) => state.assessments);
    const dispatch = useAppDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [payload, setPayload] = useState<any>({});
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultGroup, setDefaultGroup] = useState<{ label: string; value: string } | null>(null);
    const [practiceName, setPracticeName] = useState('');

    const fetchHistory = (params: any) => {
        dispatch({
            type: GET_PRACTICE_HISTORY,
            payload: { id: practiceId, params },
        });
    };

    useEffect(() => {
        dispatch({
            type: getDispatchKey,
            payload: { id: practiceId, type: practiceType },
        });
        const searchParams = new URLSearchParams(window.location.search);
        const apiParams: any = {};
        const getDefaultGroup = async (groupId: string) => {
            const groupValue = await fetchSelectedGroup(groupId);
            groupValue && setDefaultGroup(groupValue);
        };

        if (searchParams.has('page') && searchParams.has('limit')) {
            const page = parseInt(searchParams.get('page') || '1', 10);
            apiParams.page = page;
            setCurrentPage(page);
            const limit = parseInt(searchParams.get('limit') || '10', 10);
            apiParams.limit = limit;
            setPageSize(limit);
        } else {
            searchParams.set('page', currentPage.toString());
            searchParams.set('limit', pageSize.toString());
            const newUrl = `${window.location.pathname}?${searchParams}`;
            window.history.pushState({}, '', newUrl);
            apiParams.page = currentPage;
            apiParams.limit = pageSize;
        }
        if (searchParams.has('groupId')) {
            const groupId = searchParams.get('groupId');
            apiParams.groupId = groupId;
            groupId && setSelectedGroupId(groupId);
            groupId && getDefaultGroup(groupId);
        }

        setPayload(apiParams);
    }, []);

    useEffect(() => {
        if (Object.keys(payload).length === 0 && window.location.search) return;
        fetchHistory(payload);
    }, [payload, selectedGroupId]);

    const handleTableChange = (pagination: any) => {
        const newParams = new URLSearchParams(window.location.search);
        newParams.set('page', pagination.current.toString());
        newParams.set('limit', pagination.pageSize.toString());
        const newUrl = `${window.location.pathname}?${newParams}`;
        window.history.pushState({}, '', newUrl);
        setPayload((prev: any) => {
            return {
                ...prev,
                page: pagination.current,
                limit: pagination.pageSize,
                groupId: selectedGroupId,
            };
        });
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    function handleGroupChange(newValue: string) {
        setSelectedGroupId(newValue);
        setSearchParams({ page: '1', limit: '10', groupId: newValue ?? '' });
        setPayload({ page: 1, limit: 10, groupId: newValue });
        setCurrentPage(1);
        setPageSize(10);
    }

    useEffect(() => {
        practiceType === 'default' ? setPracticeName(updatedPractice?.name) : setPracticeName(assessmentData?.name);
    }, [updatedPractice, assessmentData]);

    return (
        <HistoryTable
            practiceName={practiceName}
            tableData={practiceHistory}
            pageSize={pageSize}
            currentPage={currentPage}
            setPageSize={setPageSize}
            setCurrentPage={setCurrentPage}
            handlePagination={handleTableChange}
            selectedGroupId={selectedGroupId}
            defaultGroup={defaultGroup}
            handleGroupChange={handleGroupChange}
            basePath={basePath}
        />
    );
}
