export const permissions = {
    USER: "user",
    USER_CREATE: "userCreate",
    USER_UPDATE: "userUpdate",
    USER_DELETE: "userDelete",

    GROUP: "group",
    GROUP_CREATE: "groupCreate",
    GROUP_UPDATE: "groupUpdate",
    GROUP_DELETE: "groupDelete",

    ADMIN: "admin",
    ADMIN_CREATE: "adminCreate",
    ADMIN_UPDATE: "adminUpdate",
    ADMIN_DELETE: "adminDelete",

    ROLE: "role",
    ROLE_CREATE: "roleCreate",
    ROLE_UPDATE: "roleUpdate",
    ROLE_DELETE: "roleDelete",

    QUESTION: "question",
    QUESTION_CREATE: "questionCreate",
    QUESTION_UPDATE: "questionUpdate",
    QUESTION_DELETE: "questionDelete",

    SECTION: "section",
    SECTION_CREATE: "sectionCreate",
    SECTION_UPDATE: "sectionUpdate",
    SECTION_DELETE: "sectionDelete",

    PRACTICE: "practice",
    PRACTICE_CREATE: "practiceCreate",
    PRACTICE_UPDATE: "practiceUpdate",
    PRACTICE_DELETE: "practiceDelete",

    LEVELCHECK: "levelCheck",
    LEVELCHECK_CREATE: "levelCheckCreate",
    LEVELCHECK_UPDATE: "levelCheckUpdate",
    LEVELCHECK_DELETE: "levelCheckDelete",
    LEVELCHECK_RESULT: "levelCheckResult",

    HOMEWORK: "homework",
    HOMEWORK_CREATE: "homeworkCreate",
    HOMEWORK_UPDATE: "homeworkUpdate",
    HOMEWORK_DELETE: "homeworkDelete",
    HOMEWORK_RESULT: "homeworkResult",

    EXAM: "exam",
    EXAM_CREATE: "examCreate",
    EXAM_UPDATE: "examUpdate",
    EXAM_DELETE: "examDelete",
    EXAM_RESULT: "examResult",

    FINALEXAM: "finalExam",
    FINALEXAM_CREATE: "finalExamCreate",
    FINALEXAM_UPDATE: "finalExamUpdate",
    FINALEXAM_DELETE: "finalExamDelete",
    FINALEXAM_RESULT: "finalExamResult",
};
