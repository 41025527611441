import { put, takeEvery } from 'redux-saga/effects';
import {
    setAdmins,
    setAuth,
    setClonedSections,
    setLoaders,
    setLogged,
    setNewAdmin,
    setNewPractice,
    setNewUser,
    setNotification,
    setPractice,
    setPracticeHistory,
    setSection,
    setSectionsById,
    setTransferSelectedPracticeSections,
    setUpdateSection,
    setUsers,
} from '..';
import {
    IAdmin,
    IAdminParams,
    IAdminResponse,
    ILogin,
    IPracticeParams,
    IPracticeResponse,
    IUser,
    IUserParams,
    IUserResponse,
} from '../../services/api/api-services';
import {
    ANY_SECTIONS,
    CLONE_TO_LEFT,
    CLONE_TO_RIGHT,
    CREATE_ADMIN,
    CREATE_PRACTICE,
    CREATE_SECTION,
    CREATE_USER,
    CURRENT_SECTIONS,
    DELETE_ADMIN,
    DELETE_PRACTICE,
    DELETE_SECTION,
    DELETE_USER,
    GET_ADMINS,
    GET_PRACTICE,
    GET_PRACTICES,
    GET_PRACTICE_HISTORY,
    GET_TOKEN,
    GET_USERS,
    REORDER_SECTIONS,
    SET_PRACTICE_RETRY,
    UPDATE_ADMIN,
    UPDATE_PRACTICE,
    UPDATE_SECTION,
    UPDATE_USER,
} from './types';

import { PayloadAction } from '@reduxjs/toolkit';
import queryClient from '../../app/queryClientConfig';
import AdminService from '../../services/api/api-services/adminService';
import PracticeHistoryService from '../../services/api/api-services/practiceHistoryService';
import PracticeService from '../../services/api/api-services/practiceService';
import SectionService from '../../services/api/api-services/sectionService';
import UserService from '../../services/api/api-services/userService';
import { getErrorMessage } from '../../utils/errorMessages';
import { setTableData, setTableTotal } from '../slices/boardSlice';

function* _createUser({ payload }: PayloadAction<IUser>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.addUser(payload);
        yield put(setNewUser(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'user'),
            })
        );
    }
}
function* _updateUser({ payload }: PayloadAction<{ id: string; body: IUser }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.update(payload.id, payload.body);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'user'),
            })
        );
    }
}
function* _deleteUser({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.delete(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'user'),
            })
        );
    }
}
function* _createAdmin({ payload }: PayloadAction<IAdmin>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IAdminResponse = yield AdminService.addAdmin(payload);
        yield put(setNewAdmin(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'admin'),
            })
        );
    }
}
function* _updateAdmin({ payload }: PayloadAction<{ id: string; body: IAdmin }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IAdminResponse = yield AdminService.update(payload.id, payload.body);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'admin'),
            })
        );
    }
}
function* _deleteAdmin({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IAdminResponse = yield AdminService.delete(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'admin'),
            })
        );
    }
}
function* _createPractice({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const { type, ...data } = payload;
        const response: IPracticeResponse = yield PracticeService.createPractice(data);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNewPractice(response));
        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setNotification({ type: 'success', content: 'Successfully created' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'practice'),
            })
        );
    }
}
function* _updatePractice({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.updatePractice(payload);
        yield put({
            type: GET_PRACTICE,
            payload: { id: payload.id },
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully updated' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'practice'),
            })
        );
    }
}
function* _deletePractice({ payload }: PayloadAction<{ id: string; getParams: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.delete(payload.id);
        yield put({
            type: GET_PRACTICES,
            payload: payload.getParams,
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully deleted' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'practice'),
            })
        );
    }
}
function* _cloneToLeftSections({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.postClone(payload);
        const responseW: IPracticeResponse = yield SectionService.getSectionsById(payload.toPracticeId);
        yield put(setSectionsById(responseW?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'The section has been successfully cloned' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('clone', 'sections'),
            })
        );
    }
}
function* _cloneToRightSections({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.postClone(payload);
        const updatedSections: IPracticeResponse = yield SectionService.getById(payload?.toPracticeId);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setClonedSections(updatedSections.result));
        yield put(setTransferSelectedPracticeSections(updatedSections.result));
        yield put(setNotification({ type: 'success', content: 'The section has been successfully cloned' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('clone', 'sections'),
            })
        );
    }
}
function* _updateSection({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.updateSection(payload);
        yield put(setUpdateSection(response));

        const pathParts = window.location.pathname.split('/');
        const practiceId = pathParts[2];
        const responseSec: IPracticeResponse = yield SectionService.getSectionsById(practiceId);
        yield put(setSectionsById(responseSec?.result));

        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'The section has been successfully updated' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'section'),
            })
        );
    }
}
function* _deleteSection({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.deleteSection(payload.id);
        const pathParts = window.location.pathname.split('/');
        const practiceId = pathParts[2];
        const responseSec: IPracticeResponse = yield SectionService.getSectionsById(practiceId);
        yield put(setSectionsById(responseSec?.result));

        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'The section has been successfully deleted' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'section'),
            })
        );
    }
}
function* _createSection({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.createSection(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setSection(response?.result));
        const pathParts = window.location.pathname.split('/');
        const practiceId = pathParts[2];

        const responseSec: IPracticeResponse = yield SectionService.getSectionsById(practiceId);

        yield put(setSectionsById(responseSec?.result));
        yield put(setNotification({ type: 'success', content: 'The section has been successfully created' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'section'),
            })
        );
    }
}

// not set notifications (set only error notif.)
function* _logIn({ payload }: PayloadAction<ILogin>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.login(payload);
        yield put(setAuth(response?.result?.token));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setLogged(true));
        localStorage.setItem('auth', response?.result?.token);
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setLogged(false));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('log in'),
            })
        );
    }
}
function* _getUsers({ payload }: PayloadAction<IUserParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.getUsers(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setUsers(response?.result?.data));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'users', true),
            })
        );
    }
}
function* _getAdmins({ payload }: PayloadAction<IAdminParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IAdminResponse = yield AdminService.getAdmins(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setAdmins(response?.result?.data));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'admins', true),
            })
        );
    }
}
function* _getPractices({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const { type, ...params } = payload;
        const response: IPracticeResponse = yield PracticeService.getPractices(params);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setTableData(response?.result.data));
        yield put(setTableTotal(response?.result?.total));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'practices', true),
            })
        );
    }
}

function* _getPractice({ payload }: PayloadAction<{ id: string; type: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.getPractice(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPractice(response?.result));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'practice'),
            })
        );
    }
}

function* _getPracticeHistory({ payload }: PayloadAction<{ id: string; params: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeHistoryService.getPracticeHistory(payload.id, payload.params);
        sessionStorage.setItem('totalHistory', response?.result?.total);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPracticeHistory(response?.result?.data));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'practice history'),
            })
        );
    }
}

function* _setPracticeRetry({ payload }: PayloadAction<{ practiceId: string; userIds: string[] }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.setRetry(payload);
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('retry', 'practice'),
            })
        );
    }
}

function* _getSectionsById({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.getSectionsById(payload.id);
        yield put(setSectionsById(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        setNotification({
            type: 'error',
            content: error?.response?.data?.message ?? getErrorMessage('fetch', 'sections', true),
        });
    }
}

function* _setReorderedSections({ payload }: PayloadAction<{ ids: string[] }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.reorderSections(payload);
        const pathParts = window.location.pathname.split('/');
        const practiceId = pathParts[2];
        const responseSec: IPracticeResponse = yield SectionService.getSectionsById(practiceId);
        yield put(setSectionsById(responseSec?.result));
        yield put(setNotification({ type: 'success', content: 'Saved!' }));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('reorder', 'sections'),
            })
        );
    }
}

function* _getAnySectionsById({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.getSectionsById(payload.id);
        yield put(setClonedSections(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        setNotification({
            type: 'error',
            content: error?.response?.data?.message ?? getErrorMessage('fetch', 'sections'),
        });
    }
}

function* practiceSaga() {
    yield takeEvery(GET_TOKEN, _logIn);
    yield takeEvery(GET_USERS, _getUsers);
    yield takeEvery(CREATE_USER, _createUser);
    yield takeEvery(UPDATE_USER, _updateUser);
    yield takeEvery(DELETE_USER, _deleteUser);
    yield takeEvery(GET_ADMINS, _getAdmins);
    yield takeEvery(CREATE_ADMIN, _createAdmin);
    yield takeEvery(UPDATE_ADMIN, _updateAdmin);
    yield takeEvery(DELETE_ADMIN, _deleteAdmin);
    yield takeEvery(GET_PRACTICE, _getPractice);
    yield takeEvery(GET_PRACTICES, _getPractices);
    yield takeEvery(CREATE_PRACTICE, _createPractice);
    yield takeEvery(UPDATE_PRACTICE, _updatePractice);
    yield takeEvery(DELETE_PRACTICE, _deletePractice);
    yield takeEvery(CREATE_SECTION, _createSection);
    yield takeEvery(UPDATE_SECTION, _updateSection);
    yield takeEvery(DELETE_SECTION, _deleteSection);
    yield takeEvery(CLONE_TO_LEFT, _cloneToLeftSections);
    yield takeEvery(CLONE_TO_RIGHT, _cloneToRightSections);
    yield takeEvery(CURRENT_SECTIONS, _getSectionsById);
    yield takeEvery(ANY_SECTIONS, _getAnySectionsById);
    yield takeEvery(GET_PRACTICE_HISTORY, _getPracticeHistory);
    yield takeEvery(SET_PRACTICE_RETRY, _setPracticeRetry);
    yield takeEvery(REORDER_SECTIONS, _setReorderedSections);
}
export default practiceSaga;
