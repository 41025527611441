import { IPracticeResponse } from '.';

import { BaseService } from '../baseService';

class PermissionsService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/roles/current');
    }

    async getPermissions() {
        const response = await this.get({});
        return response;
    }
}

export default new PermissionsService();
