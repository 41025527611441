import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function formatDateToReadable(dateString: string): string {
    const date = dayjs(dateString);
    return date.format('MM-DD-YYYY, HH:mm');
}

export function formatDateToUtc(dateString: string): string {
    const dateObj = new Date(dateString.replace(' ', 'T'));
    const formattedDate = dateObj.toISOString();
    return formattedDate;
}

export function formatDayjsToUtc(input: string): string {
    const formattedDate = dayjs(input).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    return formattedDate;
}

export function toDayjs(input: string): Dayjs {
    return dayjs(input);
}
