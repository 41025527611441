import { FileAddOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, PaginationProps, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { useMessage } from '../../hooks/useMassage';
import { getErrorMessage } from '../../utils/errorMessages';
import './index.css';

interface CustomTableProps {
    title: string;
    service: {
        getById: (id: string) => Promise<any>;
        delete: (id: string) => Promise<any>;
    };
    permissions?: { canCreate: boolean; canUpdate: boolean; canDelete: boolean } | null;
    fetchData: (page: number, pageSize: number, search: string) => Promise<any>;
    getColumns: (
        handleEdit: (id: string) => void,
        handleDelete: (id: string) => void,
        canEdit: boolean,
        canDelete: boolean
    ) => any[];
    ModalComponent?: React.ComponentType<any>;
}

export const CustomTable = ({
    title,
    service,
    fetchData,
    getColumns,
    ModalComponent,
    permissions,
}: CustomTableProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [isFetchingEditData, setIsFetchingEditData] = useState(false);

    const { ctxHolder, onSuccess, onError } = useMessage();
    const debValue = useDebounce(search.trim(), 800);

    const refreshTable = async () => {
        try {
            const response = await fetchData(currentPage, pageSize, debValue);
            setTotal(response?.result?.total);
            setData(response?.result?.data);
        } catch {
            onError(getErrorMessage('fetch', `${title}s`, true));
        }
    };

    const handleDelete = useCallback(
        async (id: string) => {
            try {
                if (!id) {
                    onError('Delete failed: No ID provided');
                    return;
                }
                await service.delete(id);
                await refreshTable();
                await onSuccess(`The ${title.toLowerCase()} has been successfully deleted.`);
            } catch (error) {
                onError(getErrorMessage('delete', `${title}`));
            }
        },
        [service, title, currentPage, pageSize, debValue]
    );

    const handleEdit = useCallback(
        async (id: string) => {
            try {
                if (!id) {
                    onError('Edit failed: No ID provided');
                    return;
                }
                setIsModalOpen(true);
                setIsFetchingEditData(true);

                const response = await service.getById(id);

                if (!response || !response.result) {
                    throw new Error('No response or result from getById');
                }

                setEditingItem(response.result);
            } catch (error) {
                onError(getErrorMessage('fetch', `${title} details`));
                setIsModalOpen(false);
            } finally {
                setIsFetchingEditData(false);
            }
        },
        [service, title]
    );

    const handleAddNew = () => {
        setEditingItem(null);
        setIsModalOpen(true);
    };

    const onPaginationChange: PaginationProps['onChange'] = (current, pageSize) => {
        setCurrentPage(current);
        setPageSize(pageSize);
    };

    useEffect(() => {
        setTableLoading(true);
        refreshTable().finally(() => setTableLoading(false));
    }, [pageSize, currentPage, debValue]);

    const columns = useMemo(() => {
        if (permissions) {
            return getColumns(handleEdit, handleDelete, permissions.canUpdate, permissions.canDelete);
        } else {
            return getColumns(handleEdit, handleDelete, false, false);
        }
    }, [getColumns, handleEdit, handleDelete, permissions]);

    return (
        <>
            {ctxHolder}
            <div className="table-wrapper">
                <Button
                    size="large"
                    icon={<FileAddOutlined />}
                    className="success-btn mb-15p mobile-full-width"
                    onClick={handleAddNew}
                    disabled={!permissions?.canCreate}
                >
                    Add New {title}
                </Button>
                <Input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                    }}
                />
            </div>

            <Table
                bordered
                scroll={{ x: 400 }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={tableLoading}
            />

            <Pagination
                align="end"
                className="mt-15p"
                defaultCurrent={currentPage}
                current={currentPage}
                onChange={onPaginationChange}
                showSizeChanger
                total={total}
            />

            {ModalComponent && (
                <ModalComponent
                    onSuccess={refreshTable}
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setEditingItem(null);
                    }}
                    editingItem={editingItem}
                    isFetchingEditData={isFetchingEditData}
                />
            )}
        </>
    );
};
