import Admin from '../pages/admin/Admin';
import ExamResults from '../pages/exams/exam-results/ExamResults';
import FinalExamResults from '../pages/final-exams/final-exam-results/FinalExamResults';
import { Group } from '../pages/group/Group';
import HomeworkResults from '../pages/homework/homework-results/HomeworkResults';
import { IRoute } from '.';
import LevelCheckResults from '../pages/level-check/level-check-results/LevelCheckResults';
import NoPermission from '../pages/no-permission/NoPermission';
import NotFoundPage from '../components/layout/result/NotFoundPage';
import { Outlet } from 'react-router-dom';
import { Role } from '../pages/roles/Roles';
import ServerError from '../pages/server-error/serverError';
import TransferApp from '../components/list/transfer-app/TransferApp';
import { lazy } from 'react';
import { permissions } from '../constants/permissions';

const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Users = lazy(() => import('../pages/users/Users'));
const SignIn = lazy(() => import('../pages/signin/SignIn'));
const Practices = lazy(() => import('../pages/practices'));
const Homework = lazy(() => import('../pages/homework'));
const CreatePractice = lazy(() => import('../pages/practices/new-practice/CreatePractice'));
const CreateHomework = lazy(() => import('../pages/homework/new-homework/CreateHomework'));
const UpdatePractice = lazy(() => import('../pages/practices/edit-practice/UpdatePractice'));
const UpdateHomework = lazy(() => import('../pages/homework/edit-homework/UpdateHomework'));
const LevelChecks = lazy(() => import('../pages/level-check'));
const CreateLevelCheck = lazy(() => import('../pages/level-check/new-level-check/CreateLevelCheck'));
const UpdateLevelCheck = lazy(() => import('../pages/level-check/edit-level-check/UpdateLevelCheck'));
const Exams = lazy(() => import('../pages/exams'));
const CreateExam = lazy(() => import('../pages/exams/new-exam/CreateExam'));
const UpdateExam = lazy(() => import('../pages/exams/edit-exam/UpdateExam'));
const FinalExams = lazy(() => import('../pages/final-exams'));
const CreateFinalExam = lazy(() => import('../pages/final-exams/new-final-exam/CreateFinalExam'));
const UpdateFinalExam = lazy(() => import('../pages/final-exams/edit-final-exam/UpdateFinalExam'));
const Questions = lazy(() => import('../pages/questions'));
const NewQuestion = lazy(() => import('../pages/questions/new-question/NewQuestion'));
const Sections = lazy(() => import('../pages/sections'));
const NewSections = lazy(() => import('../pages/sections/new-section'));

export const routes: IRoute[] = [
    {
        key: 'dashboard',
        type: 'private',
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        key: 'questions',
        type: 'private',
        path: '/questions',
        permission: 'question',
        element: <Outlet />,
        children: [
            {
                key: 'questions-main',
                index: true,
                element: <Questions defaultView="table" />,
            },
            {
                key: 'questions-new',
                path: 'new',
                permission: 'questionCreate',
                element: <NewQuestion />,
            },
            {
                key: 'questions-by-id',
                path: ':questionId',
                permission: 'questionUpdate',
                element: <NewQuestion />,
            },
            {
                key: 'questions-folders',
                path: 'folders',
                element: <Questions defaultView="folder" />,
                children: [
                    {
                        key: 'questions-folders-by-id',
                        path: ':folderId',
                        element: <p></p>,
                    },
                ],
            },
        ],
    },

    {
        key: 'sections',
        type: 'private',
        path: '/sections',
        permission: 'section',
        element: <Outlet />,
        children: [
            {
                key: 'sections-main',
                index: true,
                element: <Sections defaultView="table" />,
            },
            {
                key: 'sections-new',
                path: 'new',
                permission: 'sectionCreate',
                element: <NewSections />,
            },
            {
                key: 'sections-by-id',
                path: ':sectionId',
                permission: 'sectionUpdate',
                element: <NewSections />,
            },
            {
                key: 'sections-folders',
                path: 'folders',
                element: <Sections defaultView="folder" />,
                children: [
                    {
                        key: 'sections-folders-by-id',
                        path: ':folderId',
                        element: <p></p>,
                    },
                ],
            },
        ],
    },
    {
        key: 'practices',
        type: 'private',
        path: '/practices',
        element: <Outlet />,
        permission: 'practice',
        children: [
            {
                key: 'practices-main',
                index: true,
                element: <Practices defaultView="table" />,
            },
            {
                key: 'practices-new',
                path: 'new',
                permission: 'practiceCreate',
                element: <CreatePractice />,
            },
            {
                key: 'practices-by-id',
                path: ':practiceId',
                permission: 'practiceUpdate',
                element: <UpdatePractice />,
                children: [
                    {
                        key: 'practices-sections',
                        path: 'sections',
                        children: [
                            {
                                key: 'practices-clone',
                                path: 'clone',
                                element: <TransferApp />,
                            },
                            {
                                key: 'practices-sections-by-id',
                                path: ':sectionId',
                                element: <p></p>,
                            },
                        ],
                    },
                ],
            },
            {
                key: 'practices-folders',
                path: 'folders',
                element: <Practices defaultView="folder" />,
                children: [
                    {
                        key: 'practices-folders-by-id',
                        path: ':folderId',
                        element: <p></p>,
                    },
                ],
            },
        ],
    },
    {
        key: 'homework',
        type: 'private',
        path: '/homework',
        permission: 'homework',
        element: <Outlet />,
        children: [
            { key: 'homework-main', index: true, element: <Homework defaultView="table" /> },
            { key: 'homework-new', path: 'new', permission: 'homeworkCreate', element: <CreateHomework /> },
            {
                key: 'homework-by-id',
                path: ':practiceId',
                permission: 'homeworkUpdate',
                element: <UpdateHomework />,
                children: [
                    {
                        key: 'homework-sections',
                        path: 'sections',
                        children: [
                            { key: 'homework-clone', path: 'clone', element: <TransferApp /> },
                            { key: 'homework-sections-by-id', path: ':sectionId', element: <p></p> },
                        ],
                    },
                ],
            },
            {
                key: 'homework-history',
                path: 'history',
                permission: 'homeworkResult',
                element: <HomeworkResults />,
                children: [{ key: 'homework-history-by-id', path: ':practiceId', element: <p></p> }],
            },
            {
                key: 'homework-folders',
                path: 'folders',
                element: <Homework defaultView="folder" />,
                children: [{ key: 'homework-folders-by-id', path: ':folderId', element: <p></p> }],
            },
        ],
    },
    {
        key: 'level-checks',
        type: 'private',
        path: '/level-checks',
        permission: 'levelCheck',
        element: <Outlet />,
        children: [
            { key: 'level-checks-main', index: true, element: <LevelChecks defaultView="table" /> },
            {
                key: 'level-checks-new',
                path: 'new',
                permission: permissions.LEVELCHECK_CREATE,
                element: <CreateLevelCheck />,
            },
            {
                key: 'level-checks-by-id',
                path: ':practiceId',
                permission: permissions.LEVELCHECK_UPDATE,
                element: <UpdateLevelCheck />,
                children: [
                    {
                        key: 'level-checks-sections',
                        path: 'sections',
                        children: [
                            { key: 'level-checks-clone', path: 'clone', element: <TransferApp /> },
                            { key: 'level-checks-sections-by-id', path: ':sectionId', element: <p></p> },
                        ],
                    },
                ],
            },
            {
                key: 'level-checks-history',
                path: 'history',
                permission: permissions.LEVELCHECK_RESULT,
                element: <LevelCheckResults />,
                children: [{ key: 'level-checks-hisotry-by-id', path: ':practiceId', element: <p></p> }],
            },
            {
                key: 'level-checks-folders',
                path: 'folders',
                element: <LevelChecks defaultView="folder" />,
                children: [{ key: 'level-checks-folders-by-id', path: ':folderId', element: <p></p> }],
            },
        ],
    },
    {
        key: 'exams',
        type: 'private',
        path: '/exams',
        permission: permissions.EXAM,
        element: <Outlet />,
        children: [
            { key: 'exams-main', index: true, element: <Exams defaultView="table" /> },
            { key: 'exams-new', path: 'new', permission: permissions.EXAM_CREATE, element: <CreateExam /> },
            {
                key: 'exams-by-id',
                path: ':practiceId',
                permission: permissions.EXAM_UPDATE,
                element: <UpdateExam />,
                children: [
                    {
                        key: 'exams-sections',
                        path: 'sections',
                        children: [
                            { key: 'exams-clone', path: 'clone', element: <TransferApp /> },
                            { key: 'exams-sections-by-id', path: ':sectionId', element: <p></p> },
                        ],
                    },
                ],
            },
            {
                key: 'exams-history',
                path: 'history',
                permission: permissions.EXAM_RESULT,
                element: <ExamResults />,
                children: [{ key: 'exams-history-by-id', path: ':practiceId', element: <p></p> }],
            },
            {
                key: 'exams-folders',
                path: 'folders',
                element: <Exams defaultView="folder" />,
                children: [{ key: 'exams-folders-by-id', path: ':folderId', element: <p></p> }],
            },
        ],
    },
    {
        key: 'last-dances',
        type: 'private',
        path: '/last-dances',
        permission: permissions.FINALEXAM,
        element: <Outlet />,
        children: [
            { key: 'last-dances-main', index: true, element: <FinalExams defaultView="table" /> },
            {
                key: 'last-dances-new',
                path: 'new',
                permission: permissions.FINALEXAM_CREATE,
                element: <CreateFinalExam />,
            },
            {
                key: 'last-dances-by-id',
                path: ':practiceId',
                permission: permissions.FINALEXAM_UPDATE,
                element: <UpdateFinalExam />,
                children: [
                    {
                        key: 'last-dances-sections',
                        path: 'sections',
                        children: [
                            { key: 'last-dances-clone', path: 'clone', element: <TransferApp /> },
                            { key: 'last-dances-sections-by-id', path: ':sectionId', element: <p></p> },
                        ],
                    },
                ],
            },
            {
                key: 'last-dances-history',
                path: 'history',
                permission: permissions.FINALEXAM_RESULT,
                element: <FinalExamResults />,
                children: [{ key: 'last-dances-history-by-id', path: ':practiceId', element: <p></p> }],
            },
            {
                key: 'last-dances-folders',
                path: 'folders',
                element: <FinalExams defaultView="folder" />,
                children: [{ key: 'last-dances-folders-by-id', path: ':folderId', element: <p></p> }],
            },
        ],
    },
    { key: 'users', type: 'private', path: '/users', permission: 'user', element: <Users /> },
    { key: 'group', type: 'private', path: '/groups', permission: 'group', element: <Group /> },
    { key: 'role', type: 'private', path: '/roles', permission: 'role', element: <Role /> },
    { key: 'admin', type: 'private', path: '/admins', permission: 'admin', element: <Admin /> },
    { key: 'sign-in', type: 'auth', path: '/sign-in', element: <SignIn /> },
    { key: 'no-permission', type: 'public', path: '/no-permission', element: <NoPermission /> },
    { key: 'server-error', type: 'public', path: '/server-error', element: <ServerError /> },
    { key: 'not-found', type: 'public', path: '*', element: <NotFoundPage /> },
];
