import { put, takeEvery } from 'redux-saga/effects';
import { setLoaders, setNotification } from '..';
import { IAssessmentsParams, IResponse } from '../../services/api/api-services';
import {
    AssessmentsService,
    ExamService,
    FinalExamService,
    HomeworkService,
    LevelCheckService,
} from '../../services/api/api-services/asessmentsService';
import { setAssessment, setIsCreateSuccess } from '../slices/assessmentSlice';
import { setTableData, setTableTotal } from '../slices/boardSlice';
import { CREATE_ASSESSMENT, DELETE_ASSESSMENT, GET_ASSESSMENT, GET_ASSESSMENTS, UPDATE_ASSESSMENT } from './types';

import { PayloadAction } from '@reduxjs/toolkit';
import queryClient from '../../app/queryClientConfig';
import { getErrorMessage } from '../../utils/errorMessages';

export const serviceMap: Record<string, AssessmentsService> = {
    levelCheck: LevelCheckService,
    exam: ExamService,
    finalExam: FinalExamService,
    homework: HomeworkService,
};

function* _createAssessment({ payload }: PayloadAction<{ data: any; type: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const service = serviceMap[payload.type];
        const response: IResponse = yield service.createAssessment(payload.data);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setIsCreateSuccess(response.success));

        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setNotification({ type: 'success', content: 'Successfully created' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', payload.type),
            })
        );
    }
}

function* _updateAssessment({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const service = serviceMap[payload.type];
        const response: IResponse = yield service.updateAssessment({ id: payload.id, body: payload.data });
        yield put({
            type: GET_ASSESSMENT,
            payload: { id: payload.id, type: payload.type },
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully updated' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update'),
            })
        );
    }
}
function* _deleteAssessment({ payload }: PayloadAction<{ id: string; type: string; getParams: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const service = serviceMap[payload.type];
        const response: IResponse = yield service.deleteAssessment(payload.id);
        yield put({
            type: GET_ASSESSMENTS,
            payload: { type: payload.type, ...payload.getParams },
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully deleted' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete'),
            })
        );
    }
}

function* _getAssessments({ payload }: PayloadAction<IAssessmentsParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const service = serviceMap[payload.type];
        const { type, ...params } = payload;
        const response: IResponse = yield service.getAssessments(params);
        sessionStorage.setItem('totalPractices', response?.result?.total);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setTableData(response?.result.data));
        yield put(setTableTotal(response?.result?.total));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'practices', true),
            })
        );
    }
}

function* _getAssessment({ payload }: PayloadAction<{ id: string; type: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const service = serviceMap[payload.type];
        const response: IResponse = yield service.getAssessment(payload.id);
        yield put(setAssessment(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', payload.type),
            })
        );
    }
}

function* assessmentsSaga() {
    yield takeEvery(GET_ASSESSMENT, _getAssessment);
    yield takeEvery(GET_ASSESSMENTS, _getAssessments);
    yield takeEvery(CREATE_ASSESSMENT, _createAssessment);
    yield takeEvery(UPDATE_ASSESSMENT, _updateAssessment);
    yield takeEvery(DELETE_ASSESSMENT, _deleteAssessment);
}

export default assessmentsSaga;
