import { getGroups, useGetCols } from './helpers';

import { CustomTable } from '../../components/CustomTable';
import useCrudPermissions from '../../hooks/useCrudPermissions';
import groupService from '../../services/api/api-services/groupService';
import { GroupModal } from './components/GroupModal';

export const Group = () => {
    const crudPermissions = useCrudPermissions('group');
    return (
        <CustomTable
            title="Group"
            service={{
                getById: (id) => groupService.getGroupById(id),
                delete: (id) => groupService.deleteGroup(id),
            }}
            fetchData={getGroups}
            getColumns={useGetCols}
            ModalComponent={GroupModal}
            permissions={crudPermissions}
        />
    );
};
