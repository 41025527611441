import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import PopConfirmApp from '../../../components/button/PopConfirmApp';
import { IPracticeTableDataConfig } from '../../../components/tableView';
import adminService from '../../../services/api/api-services/adminService';
import { getRoles } from '../../roles/helpers';

export interface AdminValues {
    _id: string;
    name: string;
    login: string;
    password: string;
    roleId: string;
}
export const useGetAdminCols = (
    handleEdit: (id: string) => void,
    handleDelete: (id: string) => void,
    canEdit?: boolean,
    canDelete?: boolean
) => {
    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 800,
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            width: 300,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (_: any, rec: any) => rec.role?.name || 0,
            width: 300,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: unknown, record: IPracticeTableDataConfig) => (
                <div className="d-flex-even">
                    <Button disabled={!canEdit} icon={<EditOutlined />} onClick={() => handleEdit(record._id)} />

                    <PopConfirmApp
                        title="Are you sure you want to delete this admin?"
                        description="This action cannot be undone."
                        confirm={() => handleDelete(record?._id)}
                        children={<Button disabled={!canDelete} icon={<DeleteOutlined />} danger></Button>}
                    />
                </div>
            ),
            width: 200,
        },
    ];
};

export async function getAdmin(currentPage: number = 0, pageSize: number = 10, search: string) {
    const api = adminService;
    let result;
    if (search) {
        result = api.getAdmins({ page: currentPage, limit: pageSize, search: search });
    } else {
        result = api.getAdmins({ page: currentPage, limit: pageSize });
    }
    return result.then((response) => response);
}

export const useGetAdminValues = (values: AdminValues) => {
    return {
        name: values.name ?? '',
        login: values.login ?? '',
        roleId: values.roleId ?? '',
        password: values.password ?? '',
    };
};

export const fetchRoles = async () => {
    try {
        const response = await getRoles();
        return response.result.data || [];
    } catch (error) {
        return error;
    }
};
