import { setLoaders, setLogged } from '../../../redux-toolkit';

import { LogoutOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';

export default function Logout() {
    const [visible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(setLoaders({ signLoad: false }));
        dispatch(setLogged(false));
        navigate('/');
        sessionStorage.clear();
        localStorage.removeItem('auth');
    };

    return (
        <>
        <Button
            type="text"
            data-testid="open-logout-modal"
            style={{ width: 64, height: 64 }}
            onClick={() => setVisible(true)}
            icon={<LogoutOutlined className="menu-icon" />}
        />
        <Modal
            title={'Log out'}
            open={visible}
            onCancel={(e) => {
                e.stopPropagation();
                setVisible(false);
            }}
            footer={[
                <Button
                    key="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        setVisible(false);
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    key="delete"
                    type="primary"
                    data-testid="logout-button"
                    danger
                    onClick={(e) => {
                        e.stopPropagation();
                        handleLogout();
                    }}
                >
                    Yes
                </Button>
            ]}
        >
            <p>Are you sure you want to log out?</p>
        </Modal>
        </>
    );
}
