import { Navigate, Route, Routes } from 'react-router-dom';

import { IRoute } from '.';
import NoPermission from '../pages/no-permission/NoPermission';
import { routes } from './routes';
import { useAppSelector } from '../app/hooks';
import usePermissions from '../hooks/usePermissions';

const AppRoutes = () => {
    const isAuthenticated = localStorage.getItem('auth');
    const { permissions } = useAppSelector((state) => state.global);
    const { hasPermission } = usePermissions();

    const renderRoutes = (routes: IRoute[]) => {
        return routes.map((route) => {
            if (route.type === 'auth' && isAuthenticated) {
                return <Route key={route.key} path={route.path} element={<Navigate to="/dashboard" />} />;
            }

            if (route.permission && permissions && !hasPermission(route.permission)) {
                return <Route key="no-permission" path="*" element={<NoPermission />} />;
            }

            return route.path ? (
                <Route key={route.key} path={route.path} element={route.element}>
                    {route.children && renderRoutes(route.children)}
                </Route>
            ) : (
                <Route key={route.key} index element={route.element} />
            );
        });
    };

    return <Routes>{renderRoutes(routes)}</Routes>;
};

export default AppRoutes;
