import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';
import roleService from '../../../services/api/api-services/roleService';
import { useMessage } from '../../../hooks/useMassage';
import axios from 'axios';
import { RoleCheckbox } from './RoleCheckbox';
import { RoleValues, useGetRoleValues } from '../helpers';
import { useEnterModal } from '../../../hooks/useEnterModal';

interface RoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    editingItem: RoleValues;
}

export const RolesModal = ({ isOpen, onClose, onSuccess, editingItem }: RoleModalProps) => {
    const [form] = Form.useForm<RoleValues>();
    const api = roleService;
    const { ctxHolder, onError, onSuccess: onSuccessMessage } = useMessage();

    function updateRoleById(id: string, data: any) {
        return api.updateRole(id, data);
    }
    useEffect(() => {
        if (editingItem) {
            form.setFieldsValue(useGetRoleValues(editingItem));
        } else {
            form.resetFields();
        }
    }, [editingItem, form]);
    const onSubmit = async () => {
        try {
            const values = await form.validateFields();
            const result = useGetRoleValues(values);
            const hasSelectedCheckbox = Object.entries(result)
                .filter(([key]) => key !== 'name')
                .some(([_, value]) => value === true);

            if (!hasSelectedCheckbox) {
                onError('Please select at least one option');
                return;
            }

            if (editingItem) {
                await updateRoleById(editingItem._id, result);
                onSuccessMessage('The role has been successfully updated.');
            } else {
                await api.createRole(result);
                onSuccessMessage('The role has been successfully created.');
            }
            onSuccess();
            form.resetFields();
            onClose();
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                onError(error.response?.data?.message || error.message);
            } else {
                onError((error as Error).message || 'Unknown error');
            }
        }
    };
    useEnterModal(isOpen, onSubmit);

    return (
        <>
            {ctxHolder}
            <Modal
                title={editingItem ? 'Edit Role' : 'Create Role'}
                open={isOpen}
                onCancel={() => {
                    form.resetFields();
                    onClose();
                }}
                onOk={onSubmit}
                okText={editingItem ? 'Edit' : 'Save'}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Role Name"
                        rules={[{ required: true, message: 'Please input role name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="permissions" label="Permissions" help="Please select at least one option">
                        <RoleCheckbox />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
