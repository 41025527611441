import { put, takeEvery } from 'redux-saga/effects';
import { setLoaders, setNotification } from '..';
import { IPracticeParams, IPracticeResponse } from '../../services/api/api-services';
import { CREATE_QUESTION, DELETE_QUESTION, GET_QUESTION, GET_QUESTION_BY_ID, UPDATE_QUESTION } from './types';

import { PayloadAction } from '@reduxjs/toolkit';
import queryClient from '../../app/queryClientConfig';
import questionsService from '../../services/api/api-services/questionsService';
import { getErrorMessage } from '../../utils/errorMessages';
import { setTableData, setTableTotal } from '../slices/boardSlice';
import { setQuestionById } from '../slices/questionsSlice';

function* _createQuestion({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        console.log(payload);
        yield questionsService.createQuestion(payload.questions);
        yield put(setLoaders({ layoutLoad: false }));
        queryClient.invalidateQueries({ queryKey: ['folder_items'] });
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('create', 'questions'),
            })
        );
    }
}

function* _getQuestions({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    const { type, ...params } = payload;
    try {
        const response: IPracticeResponse = yield questionsService.getQuestions(params);
        sessionStorage.setItem('totalPractices', response?.result?.total);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setTableData(response?.result?.data));
        yield put(setTableTotal(response?.result?.total));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'questions', true),
            })
        );
    }
}

function* _deleteQuestion({ payload }: PayloadAction<{ id: string; getParams: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        yield questionsService.deleteQuestion(payload.id);
        yield put({
            type: GET_QUESTION,
            payload: payload.getParams,
        });
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success', content: 'Successfully deleted' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('delete', 'question'),
            })
        );
    }
}

function* _getQuestionById({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield questionsService.getQuestionById(payload.id);
        yield put(setQuestionById(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
        if (response.result) {
            yield put({
                type: 'global/appendCustomViewData',
                payload: response.result,
            });
        }
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
    }
}

function* _updateQuestion({ payload }: PayloadAction<{ id: string; body: any }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        yield questionsService.update(payload.id, payload.body);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('update', 'question'),
            })
        );
    }
}

function* questionSaga() {
    yield takeEvery(GET_QUESTION, _getQuestions);
    yield takeEvery(CREATE_QUESTION, _createQuestion);
    yield takeEvery(DELETE_QUESTION, _deleteQuestion);
    yield takeEvery(GET_QUESTION_BY_ID, _getQuestionById);
    yield takeEvery(UPDATE_QUESTION, _updateQuestion);
}
export default questionSaga;
