import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useEffect, useState } from 'react';
import { useRemoveTags } from '../../hooks/useRemoveTags';
import { debounce } from '../../utils/debounce';

const PAGE_SIZE = 10;

interface SingleSelectProps {
    fetchOptions: (payload: any) => Promise<{ data: { label: string; value: string }[]; total: number }>;
    defaultOption?: { label: string; value: string } | null;
    selectedId?: string | null;
    handleChange?: (newValue: string) => void;
    placeholder?: string;
    size?: SizeType;
    loading?: boolean;
    isLong?: boolean;
    customLimit?: number;
}

export default function SingleSelect({
    fetchOptions,
    placeholder,
    defaultOption,
    handleChange,
    selectedId,
    size,
    loading,
    isLong,
    customLimit,
}: SingleSelectProps) {
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const [isFetchingOptions, setIsFetchingOptions] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const makeUniqueOptions = (options: { label: string; value: string }[]) => {
        const set = new Set();
        return options.filter((option) => !set.has(option.value) && set.add(option.value));
    };

    const fetchData = async () => {
        setIsFetchingOptions(true);
        const { data, total } = await fetchOptions({ page: 1, limit: customLimit ?? PAGE_SIZE });
        setOptions(defaultOption ? makeUniqueOptions([...data, defaultOption]) : data);
        setHasMore(data.length < total);
        setIsFetchingOptions(false);
    };

    useEffect(() => {
        fetchData();
    }, [defaultOption]);

    const debouncedFetchOptions = debounce(async (value: string) => {
        setSearchValue(value);
        setIsFetchingOptions(true);
        const payload = {
            page: currentPage,
            limit: customLimit ?? PAGE_SIZE,
            search: value,
        };
        const { data, total } = await fetchOptions(payload);
        setOptions(data);
        setHasMore(data.length == customLimit ?? PAGE_SIZE);
        setIsFetchingOptions(false);
    }, 300);

    function handleSearch(value: string) {
        setCurrentPage(1);
        debouncedFetchOptions(value);
    }

    const handleScroll = async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { target } = e;
        if (
            target instanceof HTMLElement &&
            target.scrollTop + target.offsetHeight === target.scrollHeight &&
            hasMore &&
            !isFetchingOptions
        ) {
            setIsFetchingOptions(true);
            const nextPage = currentPage + 1;
            const payload = {
                page: nextPage,
                limit: customLimit ?? PAGE_SIZE,
                search: searchValue,
            };
            const { data, total } = await fetchOptions(payload);
            setOptions((prevOptions) => makeUniqueOptions([...prevOptions, ...data]));
            setCurrentPage(nextPage);
            setHasMore(data.length == (customLimit ?? PAGE_SIZE));
            setIsFetchingOptions(false);
        }
    };
    return (
        <Select
            size={size ?? 'middle'}
            filterOption={false}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder={placeholder || 'Select or search options'}
            options={options.map((item) => {
                return {
                    value: item.value,
                    label: useRemoveTags(item.label),
                };
            })}
            onSearch={handleSearch}
            onChange={(value) => {
                handleChange?.(value);
            }}
            onClear={fetchData}
            value={selectedId || undefined}
            onPopupScroll={handleScroll}
            notFoundContent={isFetchingOptions ? 'Searching...' : 'No data found'}
            loading={loading}
            listHeight={isLong ? 502 : 246}
        />
    );
}
