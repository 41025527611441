import practiceService from '../../../services/api/api-services/practiceService';
import sectionService from '../../../services/api/api-services/sectionService';

export const rightOptions = [
    {
        key: 'sa-1',
        label: 'Select All',
    },
    {
        key: 'is-1',
        label: 'Invert Selection',
    },
];
export const leftOptions = [
    {
        key: 'sa-2',
        label: 'Select All',
    },
    {
        key: 'is-2',
        label: 'Invert Selection',
    },
];

export const fetchPracticeOptions = async (params?: any) => {
    const response = await practiceService.getPractices(params);
    const practicesData = response?.result?.data;
    const total = response?.result?.total;
    const selectOptions = practicesData?.map((practice: any) => {
        return {
            label: practice?.name,
            value: practice?._id,
        };
    });

    return { data: selectOptions, total };
};

export async function fetchSelectedPracticeSections(selectedPracticeId: string) {
    try {
        const res = await sectionService.getById(selectedPracticeId);
        return res.result;
    } catch (error) {
        throw new Error('Failed to fetch practice sections. Please try again.');
    }
}
