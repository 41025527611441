import { Form, Input, Modal, Skeleton } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import MultiSelect from '../../../components/select/MultiSelect';
import { useEnterModal } from '../../../hooks/useEnterModal';
import { useMessage } from '../../../hooks/useMassage';
import groupService from '../../../services/api/api-services/groupService';
import { fetchGroupUserOptions, updateGroupById } from '../helpers';

interface GroupModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    editingItem?: { _id: string; name: string; userIds: string[]; users: { name: string; _id: string }[] };
    isFetchingEditData?: boolean;
}

interface FormValues {
    name: string;
    userIds: string[];
}

export const GroupModal = ({ isOpen, onClose, editingItem, onSuccess, isFetchingEditData }: GroupModalProps) => {
    const [form] = Form.useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [defaultOptions, setDefaultOptions] = useState<{ label: string; value: string }[]>([]);
    const { ctxHolder, onSuccess: onGroupSuccess, onError } = useMessage();

    useEffect(() => {
        if (editingItem) {
            const initialUserIds = editingItem.users.map((user) => user._id);
            const initialOptions = editingItem.users.map((user) => ({
                label: `${user.name}`,
                value: user._id,
            }));

            form.setFieldsValue({
                name: editingItem.name,
                userIds: initialUserIds,
            });
            setSelectedUsers(initialUserIds);
            setDefaultOptions(initialOptions);
        } else {
            form.resetFields();
            setSelectedUsers([]);
            setDefaultOptions([]);
        }
    }, [editingItem, form]);

    const handleUserChange = (newValue: string[]) => {
        setSelectedUsers(newValue);
        form.setFieldValue('userIds', newValue);
    };

    const onSubmit = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            if (editingItem) {
                await updateGroupById(editingItem._id, values);
                onGroupSuccess('The group has been successfully updated.');
            } else {
                await groupService.createGroup(values);
                onGroupSuccess('The group has been successfully created.');
            }
            onSuccess();
            form.resetFields();
            setSelectedUsers([]);
            setDefaultOptions([]);
            onClose();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                onError(error.message);
            } else {
                onError((error as Error).message || 'Unknown error');
            }
        } finally {
            setLoading(false);
        }
    };

    useEnterModal(isOpen, onSubmit);

    return (
        <>
            {ctxHolder}
            <Modal
                title={`${editingItem ? 'Edit' : 'Create'} Group`}
                open={isOpen}
                onCancel={() => {
                    form.resetFields();
                    setSelectedUsers([]);
                    setDefaultOptions([]);
                    onClose();
                }}
                onOk={form.submit}
                okText={editingItem ? 'Edit' : 'Save'}
                confirmLoading={loading}
            >
                {isFetchingEditData ? (
                    <Skeleton active paragraph={{ rows: 4 }} />
                ) : (
                    <Form form={form} layout="vertical" onFinish={onSubmit}>
                        <Form.Item
                            name="name"
                            label="Group Name"
                            rules={[{ required: true, message: 'Please input group name!' }]}
                        >
                            <Input placeholder="Group name" />
                        </Form.Item>
                        <Form.Item
                            name="userIds"
                            label="Users"
                            rules={[{ required: true, message: 'Please select at least one user!' }]}
                        >
                            <MultiSelect
                                fetchOptions={fetchGroupUserOptions}
                                selectedIds={new Set(selectedUsers)}
                                defaultOption={defaultOptions}
                                handleChange={handleUserChange}
                                placeholder="Select or search users"
                                loading={loading}
                            />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </>
    );
};
