import { IAdmin, IAdminParams, IAdminResponse } from ".";
import { BaseService } from "../baseService";

class AdminService extends BaseService<any, IAdminResponse> {
    constructor() {
        super('/admins');
    }

    async getAdmins(data: IAdminParams) {
        const response = await this.get(data);
        return response;
    }
    async addAdmin(data: IAdmin) {
        const response = await this.post(data);
        return response;
    }
    async updateAdmin(id: string, data: IAdmin) {
        const response = await this.update(id, data);
        return response;
    }
    async deleteAdmin(id: string) {
        const response = await this.delete(id);
        return response;
    }
}

export default new AdminService();