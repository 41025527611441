import { Popconfirm, Tooltip } from 'antd';

import { IConfirmProps } from '.';

export default function PopConfirmApp(props: IConfirmProps) {
    const { children, confirm, id, tooltip, title, description } = props;

    return (
        <Tooltip title={tooltip} trigger={'hover'}>
            <Popconfirm
                okText="Yes"
                id={id ?? ''}
                cancelText="No"
                onCancel={() => {}}
                title={title ?? "Are you sure you want to delete this?"}
                onConfirm={confirm}
                description={description ?? "This action cannot be undone"}
            >
                {children}
            </Popconfirm>
        </Tooltip>
    );
}
