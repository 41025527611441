import { BaseService } from '../baseService';
import { IPracticeResponse } from '.';

class PracticeHistoryService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/practice-result/pagin');
    }

    async getPracticeHistory(id: string, params: any) {
        const response = await this.getById(id, '', params);
        return response;
    }
}

export default new PracticeHistoryService();
