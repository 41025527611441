import { Drawer, Layout, Menu } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import usePermissions from '../../../hooks/usePermissions';
import { LocalizationData } from '../../../utils/helpers';
import { getActiveKey, getFilteredMenuItems } from './utils/menuUtils';

const { Sider } = Layout;

export default function Sidebar(props: ISidebarProps) {
    const navigate = useNavigate();
    const { isMobile } = useAppSelector((state) => state.global);
    const { hasPermission } = usePermissions();

    const menuItems: NavigationMenu = LocalizationData.components.mainLayout.sidebar.menuList(
        navigate
    ) as NavigationMenu;

    const filteredMenuItems = useMemo(() => getFilteredMenuItems(menuItems, hasPermission), [menuItems, hasPermission]);
    const activeKey = useMemo(() => getActiveKey(menuItems, location.pathname), [location.pathname, menuItems]);
    
    return (
        <>
            {!isMobile && (
                <Sider trigger={null} collapsible collapsed={props.collapsed} theme="light">
                    <Menu
                        theme="light"
                        mode="inline"
                        className={props.collapsed ? "collapsed-menu" : "left-menu"}
                        defaultSelectedKeys={[sessionStorage.getItem('key') ?? '0']}
                        selectedKeys={[activeKey]}
                        onSelect={(e: any) => sessionStorage.setItem('key', e?.key)}
                        items={filteredMenuItems}
                    />
                </Sider>
            )}
            {isMobile && (
                <Drawer
                    title="Close"
                    className="resp-drawer"
                    placement={'left'}
                    closable={true}
                    onClose={props.onClose}
                    open={!props.collapsed}
                    key={'left'}
                    size="default"
                >
                    <Menu
                        theme="light"
                        mode="inline"
                        className="left-mobile-menu"
                        selectedKeys={[activeKey]}
                        defaultSelectedKeys={[sessionStorage.getItem('key') ?? '0']}
                        onSelect={(e: any) => sessionStorage.setItem('key', e?.key)}
                        items={filteredMenuItems}
                    />
                </Drawer>
            )}
        </>
    );
}
