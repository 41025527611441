import axios from 'axios';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth'); // Retrieve the token from localStorage
        if (token && config.headers) {
            (config.headers as any)['Authorization'] = `Bearer ${token}`; // Set the token in the Authorization header
        }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status } = error.response;
            switch (true) {
                case status === 401:
                    localStorage.removeItem('auth');
                    history.push('/sign-in');
                    break;
                case status === 403:
                    history.push('/no-permission');
                    break;
                case status >= 500:
                    history.push('/server-error');
                    break;
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
