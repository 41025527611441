import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';

import { Spin } from 'antd';
import MainLayout from './components/layout/MainLayout';
import useFetchPermissions from './hooks/useFetchPermissions';
import { setLogged } from './redux-toolkit';
import AppRoutes from './routes/index.config';

const SignIn = lazy(() => import('./pages/signin/SignIn'));

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { isLogged } = useAppSelector((state) => state.global);
    const hasNotAuth = !isLogged && localStorage.getItem('auth') !== null;
    useFetchPermissions();

    useEffect(() => {
        if (hasNotAuth) {
            dispatch(setLogged(true));
        } else if (!isLogged && location.pathname !== '/sign-in') {
            navigate(`/sign-in?redirect=${encodeURIComponent(location.pathname + location.search)}`, { replace: true });
        }
        if (isLogged) {
            const params = new URLSearchParams(location.search);
            const redirectUrl = params.get('redirect');
            if (redirectUrl) {
                navigate(decodeURIComponent(redirectUrl));
            }
        }
    }, [hasNotAuth, isLogged, location, navigate, dispatch]);

    return (
        <Suspense fallback={<Spin spinning={true} style={{ display: 'none' }}></Spin>}>
            {isLogged && <MainLayout children={<AppRoutes />} />}
            {!isLogged && (
                <Routes>
                    <Route path={'/sign-in'} element={<SignIn />} />
                    <Route path="*" element={<SignIn />} />
                </Routes>
            )}
        </Suspense>
    );
}

export default App;
