import {
    AuditOutlined,
    BookOutlined,
    CheckSquareOutlined,
    ClusterOutlined,
    ContainerOutlined,
    CrownOutlined,
    GroupOutlined,
    HomeOutlined,
    RobotFilled,
    UnorderedListOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { NavigateFunction } from 'react-router-dom';
import { permissions } from '../constants/permissions';

export const LocalizationData = {
    components: {
        mainLayout: {
            navbar: {
                headTitle: 'Admin panel',
            },
            sidebar: {
                menuList: (navigate: NavigateFunction) => [
                    {
                        key: 'group-1',
                        type: 'group',
                        label: 'Main',
                        children: [
                            {
                                key: 'dashboard',
                                icon: <HomeOutlined />,
                                label: 'Dashboard',
                                path: '/dashboard',
                                onClick: () => navigate('/dashboard'),
                            },
                            {
                                key: 'questions',
                                icon: <UnorderedListOutlined />,
                                label: 'Questions',
                                path: '/questions',
                                permission: permissions.QUESTION,
                                onClick: () => navigate('/questions'),
                            },
                            {
                                key: 'sections',
                                icon: <GroupOutlined />,
                                label: 'Sections',
                                path: '/sections',
                                permission: permissions.SECTION,
                                onClick: () => navigate('/sections'),
                            },
                            {
                                key: 'practices',
                                icon: <ClusterOutlined />,
                                label: 'Practices',
                                path: '/practices',
                                permission: permissions.PRACTICE,
                                onClick: () => navigate('/practices'),
                            },
                        ],
                    },
                    { key: 'divider-1', type: 'divider' },
                    {
                        key: 'group-2',
                        type: 'group',
                        label: 'Assessments',
                        children: [
                            {
                                key: 'homework',
                                icon: <BookOutlined />,
                                label: 'Homework',
                                path: '/homework',
                                permission: permissions.HOMEWORK,
                                onClick: () => navigate('/homework'),
                            },
                            {
                                key: 'level-checks',
                                icon: <CheckSquareOutlined />,
                                label: 'Level Checks',
                                path: '/level-checks',
                                permission: permissions.LEVELCHECK,
                                onClick: () => navigate('/level-checks'),
                            },
                            {
                                key: 'exams',
                                icon: <ContainerOutlined />,
                                label: 'Exams',
                                path: '/exams',
                                permission: permissions.EXAM,
                                onClick: () => navigate('/exams'),
                            },
                            {
                                key: 'last-dances',
                                icon: <AuditOutlined />,
                                label: 'Last Dances',
                                path: '/last-dances',
                                permission: permissions.FINALEXAM,
                                onClick: () => navigate('/last-dances'),
                            },
                        ],
                    },
                    { key: 'divider-2', type: 'divider' },
                    {
                        key: 'group-3',
                        type: 'group',
                        label: 'Management',
                        children: [
                            {
                                key: 'users',
                                icon: <UserOutlined />,
                                label: 'Users',
                                path: '/users',
                                permission: permissions.USER,
                                onClick: () => navigate('/users'),
                            },
                            {
                                key: 'groups',
                                icon: <UsergroupAddOutlined />,
                                label: 'Groups',
                                path: '/groups',
                                permission: permissions.GROUP,
                                onClick: () => navigate('/groups'),
                            },
                        ],
                    },
                    { key: 'divider-3', type: 'divider' },
                    {
                        key: 'group-4',
                        type: 'group',
                        label: 'Administration',
                        children: [
                            {
                                key: 'roles',
                                icon: <RobotFilled />,
                                label: 'Roles',
                                path: '/roles',
                                permission: permissions.ROLE,
                                onClick: () => navigate('/roles'),
                            },
                            {
                                key: 'admins',
                                icon: <CrownOutlined />,
                                label: 'Admins',
                                path: '/admins',
                                permission: permissions.ADMIN,
                                onClick: () => navigate('/admins'),
                            },
                        ],
                    },
                ],
            },
        },
    },
    pages: {
        users: {},
    },
};
