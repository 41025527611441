import { useMemo } from 'react';
import { useAppSelector } from '../app/hooks';
import { ICrudPermissions } from '../interfaces/permissions.interface';

export default function useCrudPermissions(permissionKey: string): ICrudPermissions {
    const { permissions } = useAppSelector((state) => state.global);

    return useMemo(
        () => ({
            canCreate: Boolean(permissions?.[`${permissionKey}Create`]),
            canUpdate: Boolean(permissions?.[`${permissionKey}Update`]),
            canDelete: Boolean(permissions?.[`${permissionKey}Delete`]),
        }),
        [permissions, permissionKey]
    );
}
