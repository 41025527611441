import { all } from 'redux-saga/effects';
import assessmentSaga from './assessmentSaga';
import folderSaga from './folderSaga';
import globalSaga from './globalSaga';
import practiceSaga from './practiceSaga';
import questionSaga from './questionsSaga';
import sectionsSaga from './sectionsSaga';

export default function* rootSaga() {
    yield all([globalSaga(), practiceSaga(), folderSaga(), questionSaga(), assessmentSaga(), sectionsSaga()]);
}
