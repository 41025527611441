import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";


const ServerError = () => {
    const navigate = useNavigate();
    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, the service is currently unavailable."
            extra={
                <Button type="primary" onClick={() => navigate('/dashboard')}>
                    Back Home
                </Button>
            }
        />
    )
}

export default ServerError