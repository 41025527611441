import { getRoles, useGetRoleCols } from './helpers';

import { CustomTable } from '../../components/CustomTable';
import { RolesModal } from './components/RolesModal';
import roleService from '../../services/api/api-services/roleService';
import useCrudPermissions from '../../hooks/useCrudPermissions';

export const Role = () => {
    const crudPermissions = useCrudPermissions('role');

    return (
        <CustomTable
            title="Role"
            service={{
                getById: (id) => roleService.getRoleById(id),
                delete: (id) => roleService.deleteRole(id),
            }}
            fetchData={getRoles}
            getColumns={useGetRoleCols}
            ModalComponent={RolesModal}
            permissions={crudPermissions}
        />
    );
};
