import { put, takeEvery } from 'redux-saga/effects';
import { setLoaders, setNotification, setPermissions } from '..';

import { GET_PERMISSIONS } from './types';
import { IResponse } from '../../services/api/api-services';
import { getErrorMessage } from '../../utils/errorMessages';
import permissionsService from '../../services/api/api-services/permissionsService';

function* _getPermissions() {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IResponse = yield permissionsService.getPermissions();
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPermissions(response?.result));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? getErrorMessage('fetch', 'permissions', true),
            })
        );
    }
}

function* globalSaga() {
    yield takeEvery(GET_PERMISSIONS, _getPermissions);
}

export default globalSaga;
