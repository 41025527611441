import { createSlice } from '@reduxjs/toolkit';
import { IFolderState } from '.';

const initialState: IFolderState = {
    folders: [],
    folder: null,
    folderItems: [],
    folderType: '',
    itemsQueryType: '',
    isFolderSelected: false,
    filterItemName: '',
};

const foldersSlice = createSlice({
    name: 'folders',
    initialState: initialState,
    reducers: {
        setFolders: (state, action) => {
            state.folders = action.payload;
        },
        setFolder: (state, action) => {
            state.folder = action.payload;
        },
        setFolderType: (state, action) => {
            state.folderType = action.payload;
        },
        setItemsQueryType: (state, action) => {
            state.itemsQueryType = action.payload;
        },
        setFolderItems: (state, action) => {
            state.folderItems = action.payload;
        },
        setIsFolder: (state, action) => {
            state.isFolderSelected = action.payload;
        },
        setFolderProps: (state, action) => {
            state.folderType = action.payload.folderType;
            state.itemsQueryType = action.payload.itemsQueryType;
            state.filterItemName = action.payload.filterItemName;
        },
    },
});

export const { setFolders, setFolder, setFolderType, setItemsQueryType, setFolderItems, setIsFolder, setFolderProps } =
    foldersSlice.actions;
export default foldersSlice.reducer;
